import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';



const GetMembers = () => {
    const [value, setValue] = useState([])
     useEffect(() => {
        axios.get("/api/members")
        .then(res => {
            setValue(res.data)
        })
        .catch(err => console.log(err))
    }
    ,[])
    
    const deleteMembersById = (id) =>{
        console.log(id)
            axios.delete(`/api/members/delete/${id}`)
                .then(res => {
                    window.location.reload()
                })
                .catch(err => console.log(err))
    }
    return(
        <div>
        <Link className="btn btn-sm btn-link mt-3  board-button" to="/addMembers" >Add New Member</Link>
        <hr className="mt-3"/>

    <div className="container">
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col">Member</th>
                    <th scope="col">Type  Member </th>
                    <th scope="col">URL</th>
                    <th scope="col">Image</th>
                    <th scope="col">Action</th>
                    
                    </tr>
                </thead>
                <tbody>
                
                {value.map(data => (
                <tr key={data._id}>    
                <td>{data.name}</td>
                <td>{data.type_of_members}</td>
                <td>{data.url}</td>
                <td><a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api/${data.image}`}>View Photo</a></td>
                <td>
                    <Link className="btn btn-sm btn-info" to={`/editMembers/${data._id}`}>Edit</Link>
                    <button className="btn btn-sm btn-danger" type="button" onClick={() => deleteMembersById(data._id)}>Delete</button>
                </td>
                </tr>
                
                ))} 
                    
                </tbody>
            </table>
            

            </div>
       
    </div>
    )
}

export default GetMembers;