import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import './home.css'


import icon01 from '../../img/icon01.jpg'
import ICONS02 from '../../img/ICONS02.jpg'
import ICONS03 from '../../img/ICONS03.jpg'

import buletini from '../../img/buletini.jpg'
import buletinEn from '../../img/buletinEn.jpg'

import aplikacioni from '../../img/aplikacioni.jpg'
import aplicationEng from '../../img/aplicationEng.jpg'

const Home =() => {
    const [value, setValue] = useState([])
    const [coursel, setCoursel] = useState([])

    const [docs, setDocs] = useState("")
    const [docsEn, setDocsEn] = useState("")
    useEffect( () => {
        axios.get('/api/news')
            .then(res => {
                
                setValue(res.data.news)
            })
            .catch(err => console.log(err))
    },[])


    useEffect(() => {
        axios.get("/api/pdf")
            .then(res => {
                setDocs(res.data[0].docs)
                setDocsEn(res.data[0].docs_en)
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        axios.get("/api/banner")
            .then(res => {
                console.log(res.data)
                setCoursel(res.data)
            })
            .catch(err => console.log(err))
    }, [])
    const lang = localStorage.getItem("lang")




    
    return(
        <div>

                <Carousel fade>
                    {coursel.map(data => (
                        
                        <Carousel.Item interval={800}>
                            <a href={data.link} target="_blank">
                            <img
                            className="d-block w-100 img-fluid"
                            src={lang === 'al' ? (`https://www.csrkosovo.org/api/${data.photo}`) : (`https://www.csrkosovo.org/api/${data.photo_en}`)}
                            alt="..."
                            />
                            </a>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                        
                      
                    ))}
            
              
                </Carousel>
             


               <div className="row main__home">
                   
                    { 
                     value.slice(0, 3).map(data => (
                        <div className="col-lg-4 col-md-6 col-sm-12" key={data._id}>
                        <div className="row">
                            <div className="col-md-6 col-md-6 col-sm-12">
                                <Link to={`/showNews/${data._id}`}>
                                <img src={`https://www.csrkosovo.org/api/${data.image}`} className="img-fluid img_home mt-3 mb-3 ml-3 home" alt="..." />
                                </Link>
                            </div>
                            <div className="col-md-6 col-md-6 col-sm-12">
                                <h6 className="mt-3 ml-3">{lang === 'al' ? data.name : data.name_en}</h6>
                                <p className="ml-3">{data.data}</p>
                                <Link className="button__home ml-3" to={`/showNews/${data._id}`}>{lang === 'al' ? "LEXO ME SHUMË" : "READ MORE"}</Link>
                            </div>

                        </div>
                        </div> 
                    ))}
     
                </div>
                
                    <div className="d-lg-inline-flex">
                        {lang === 'al' ? (
                            <div>
                                <Link to="/buletin"> 
                                <img src={buletini} className="img-fluid img_buletin" alt="..."/>
                                </Link>
                            </div>
                        ) : (
                            <Link to="/buletin">
                            <img src={buletinEn} className="img-fluid img_buletin" alt="..."/>
                            </Link>
                        )}
                        
                   
                        {lang === 'al' ? (
                            <a href={`https://www.csrkosovo.org/api/${docs}`} >
                            <img src={aplikacioni} className="img-fluid img_docs" alt="..."s/>
                            </a>
                        ): (
                            <a href={`https://www.csrkosovo.org/api/${docsEn}`}>
                            <img src={aplicationEng} className="img-fluid img_docs" alt="..."/>
                            </a>
                        )}
                  
                </div>

                <div className="row mt-5 ml-5 mr-5 mb-5">
                    <div className="col-lg-4 col-md-12 col-sm-12 ">
                        <img  src={icon01} alt="..." width="92" height="62" className="img-fluid mx-auto d-block"/>
                       
                        {lang === 'al' ? (
                            <div>
                                <p className="text-center title__section__home">Planifikimi Strategjik</p>
                                <p className="text-center text__section__home mt-3">Rrjeti CSR Kosova ka punuar Planin Strategjik për Përgjegjësinë Shoqërore Korporative në Kosovë për periudhën 2021-2025</p>
                            </div>
                        ) : (
                            <div>
                            <p className="text-center title__section__home">Strategic Planning </p>
                            <p className="text-center text__section__home mt-3">Kosovo CSR Network has worked on the Strategic Plan for Corporate Social Responsibility in Kosovo for the period 2021-2025</p>
                            </div>
                        )}
                       
                       
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <img src={ICONS02} alt="..." width="92" height="62" className="img-fluid mx-auto d-block"/>
                        {lang === 'al' ? (
                            <div>
                                <p className="text-center title__section__home">Rritje Dinamike</p>
                                <p className="text-center text__section__home mt-3">Që nga fillimi i punës 2011, Rrjeti CSR Kosova është rritur përmes besimit të fituar tek bizneset dhe organizatat vendore</p>
                            </div>
                        ) : (
                            <div>
                            <p className="text-center title__section__home">Dynamic Growth </p>
                            <p className="text-center text__section__home mt-3">
                            Since the beginning of work in 2011, Kosovo CSR Network has grown through gaining the trust of local businesses and organizations 
                            </p>
                            </div>
                        )}
                        
                    </div>
                    <div className="col-lg-4 com-sm-12">
                        <img src={ICONS03} alt="..." width="92" height="62" className="img-fluid mx-auto d-block"/>
                        {lang === 'al' ? (
                            <div>
                                <p className="text-center title__section__home">Zgjidhje Kreative</p>
                                <p className="text-center text__section__home mt-3">Qasja e Rrjetit CSR Kosova ka bërë që në rrugëtimin drejt ndërgjegjësimit të bizneseve, të fuqizohen edhe të rinjtë e Kosovës</p>
                            </div>
                        ): (
                            <div>
                            <p className="text-center title__section__home">Creative Solutions </p>
                            <p className="text-center text__section__home mt-3">Kosovo CSR Network approach on raising the businesses awareness on sustainability, has had a direct impact on Kosovo’s youth empowerment </p>
                             </div>
                        )}
                      
                    </div>
                </div>
           </div>      


    )
}

export default Home