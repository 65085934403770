import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Project from './project/Project';
const Projects = () => {
    const lang = localStorage.getItem('lang')
    const [sizePage, setSizePage] = useState(3)
    const [project, setProject] = useState([])

    useEffect(() => {
        axios.get(`/api/project?size=${sizePage}`)
            .then(res => setProject(res.data.project))
            .catch(err => console.log(err)) 
    }, [sizePage])

    const loadProject = () => {
        setSizePage(sizePage + 5)
    }
    return(
       
        <>
        
            {project.map(project => (
              
                <Project project={project} lang={lang} key={project._id}/>
            
            ))}
            <div className="row">
                <div className="col-12 mb-3 mt-3  d-flex justify-content-center">
                <button onClick={loadProject} className="btn btn-lg "style={{background: '#34ae99', color:"white"}}>{lang == 'al' ? "Më shumë projekte" : "Load more projects"}</button>
                </div>
            </div>
            
      </>    
    )
}

export default Projects