import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const EditProject = () => {
    const { id } = useParams();
    const history = useHistory();
    const [value, setValue] = useState({
        _id: "",
        name: "",
        description: "",
        description_en: "",
        name_link: "",
        web_link: ""
    })
    const [photo, setPhoto] = useState()
    const [error, setError] = useState({
        name: "",
        description: "",
        description_en: "",
        name_link: "",
        web_link: "",
        image: ""
    })

    useEffect( () => {
        function fetchProject(){
            axios.get(`/api/project/show/${id}`)
            .then(res => setValue(res.data))
            .catch(err => console.log(err))
        }
        fetchProject()
    }, [id])

    const handleOnChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append("id", value._id)
        data.append("name", value.name)
        data.append("description",value.description)
        data.append("description_en",value.description_en)
        data.append("name_link", value.name_link)
        data.append("web_link", value.web_link)
        data.append("imageProject", photo)

        axios.post("/api/project/edit", data , {  headers: { "Content-Type": "multipart/form-data" }})
                .then(res => {
                      history.push("/getProject")
                })
                .catch(err => setError(err.response.data))
    }
   
    return(
        <div>
            <Link className="btn  btn-link board-button mt-3" to="/getProject">Go Back</Link>
            <hr />
          <div className="row d-flex justify-content-center">
            
            <div className="col-8"> 
            
            <div class="card">

            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center">Edit Project</h3>
              </div>
              <form  onSubmit={onSubmit}>
                
                <div className="card-body">
                <input type="hidden" className="form-control" id="id" name="id" value={value._id} onChange={handleOnChange} />

                <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" name="name" value={value.name} onChange={handleOnChange} placeholder="Enter Name" />
                {error.name ? error.name : null}
                </div>
                <div className="form-group">
                <label htmlFor="description">Description Albania</label>
                <textarea type="text" className="form-control" id="description" name="description" value={value.description} onChange={handleOnChange} placeholder="Description Albania" />
                {error.description ? error.description : null}
                </div>

                <div className="form-group">
                <label htmlFor="description_en">Description English</label>
                <textarea type="text" className="form-control" id="description_en" name="description_en" value={value.description_en} onChange={handleOnChange} placeholder="Description English" />
                {error.description_en ? error.description_en : null}
                </div>
                
                
                <div className="form-group">
                <label htmlFor="name_link">URL Name</label>
                <input type="text" className="form-control" id="name_link" name="name_link" value={value.name_link} onChange={handleOnChange} placeholder="URL Name" />
                {error.name_link ? error.name_link : null}
                </div>
               
                <div className="form-group">
                <label htmlFor="web_link">URL</label>
                <input type="text" className="form-control" id="web_link" name="web_link" value={value.web_link} onChange={handleOnChange} placeholder="URL" />
                {error.web_link ? error.web_link : null}
                </div>

                <div className="form-group">
                    <label htmlFor="photo">Photo</label>
                    <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  placeholder="Enter Positon Job in english" />
                    <a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api/${value.image}`}>View Photo</a>
                </div>


                </div>

                <div className="card-footer d-flex justify-content-center mb-3">
                  <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
                </div>
              </form>
            </div>
        </div>
    </div>
    </div>
    )
}

export default EditProject