import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import photo1 from '../../../img/photo1.png';
import CSRlogo from '../../../img/CSRlogo.png';
import './Header.css'

const Header = () => {
    const lang = localStorage.getItem('lang') || 'al';
    const [value, setValue] = useState({
        statut_al:"",
        strategy_al: "",
        statut_en: "",
        strategy_en: ""
    })
    useEffect(() => {
      axios.get("/api/pdf")
            .then(res => {
              if(res.data.length !== 0){
                setValue(res.data[0])
              }
            })
            .catch(err => console.log(err))
    }, [])
    const onChange  = (e) => {
      
      localStorage.setItem("lang", e.target.value)
      window.location.reload();
    }
        return(
        <nav className="navbar navbar-expand-lg ">
        <Link to={'/'}>
        <img src={CSRlogo} className="logo__header" alt="..."/>
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <div className="icon_menu"></div>
        <div className="icon_menu"></div>
        <div className="icon_menu"></div>
        </button>
      
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
          <li className="nav-item dropdown mt-1">
              <Link className="links_menu dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {lang === 'al'? "RRETH NESH" : "ABOUT US" }
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/connection">{lang === 'al' ? "Rrjeti  CSR Kosova": "Kosovo CSR Network"}</Link>
                <Link className="dropdown-item" to="/mision">{lang === 'al' ? "Misioni dhe Vizioni" : "Mission and Vision"}</Link>
                <Link className="dropdown-item" to="/board" >{lang === 'al' ? "Bordi dhe Menaxhmenti" : "Board and Management"}</Link>
                {lang === 'al'?  ( <a className="dropdown-item" href={`https://www.csrkosovo.org/api/${value.statut_al}`}>Statuti i Rrjetit CSR Kosova</a>) : (<a className="dropdown-item" href={`https://www.csrkosovo.org/api/${value.statut_en}`} >By-Laws</a>)}
               
                {lang === 'al'? ( <a className="dropdown-item" href={`https://www.csrkosovo.org/api/${value.strategy_al}`}>Strategjia 2021-2025</a>)  : (<a className="dropdown-item" href={`https://www.csrkosovo.org/api/${value.strategy_en}`}>Strategy 2021-2025</a>)}
              </div>
            </li>
            <li className="nav-item mt-1">
             <Link className="links_menu" to="/project">{lang === 'al' ? 'PROJEKTET' : 'PROJECTS'}</Link>
            </li>
            <li class="nav-item mt-1">
              <Link className="links_menu" to="/members">{lang === 'al' ? 'ANËTARËT' : 'MEMBERS'}</Link>
            </li>
            <li class="nav-item mt-1">
              <Link className="links_menu" to="/news">{lang === 'al' ? 'LAJME DHE NGJARJE' : 'NEWS AND EVENTS'}</Link>
            </li> 
            <li className="nav-item">
            <select className="form-select form-select-sm" onChange={onChange} value={lang} >
              <option  value="al">SHQIP</option>
              <option  value="en">ENGLISH</option>
            </select>
            </li>
            
          </ul>


          <ul className="navbar-nav ">
          
          <li className="nav-item">
              <b className="mr-2">Member of</b>
              <a href="https://www.csreurope.org/" target="_blank">
              <img src={photo1} />
              </a>
          </li>
          </ul>
          
       
        </div>
      </nav>
    )
}

export default Header