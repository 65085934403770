import React from 'react';
import { Link, useHistory } from 'react-router-dom';


const  Header = () => {
        const history = useHistory()
        const onDeleteTokenLocalStorage = () =>{
          localStorage.removeItem('token');
          history.push('/')
        } 
        return(

        
            <div>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
              {/* Left navbar links */}
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" data-widget="pushmenu" ><i className="fas fa-bars" /></Link>
                </li>
                
              </ul>
              <ul class="navbar-nav ml-auto">
                <li className="nav-item">
                  <button className="btn btn-sm btn-link"onClick={onDeleteTokenLocalStorage}>Log out</button>
                </li>
              </ul>
         
              
            </nav>
          </div>
       
  
        );
}


export default Header;