import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const GetFooter = () => {
    const [value, setValue] =useState({
        _id: "",
        instagram_url: "",
        facebook_url: "",
        twitter_url: "",
        linkedin_url: "",
        address: "",
        email: ""
    })

    useEffect(() => {
        axios.get("/api/footer")
            .then(res => 
                {
                    setValue(res.data[0])   
                })
            .catch(err => console.log(err))
    }, [])


 
    return(
        <div>
            <div className="d-flex justify-content-between">
            <div>
            {value ? (<Link className="btn btn-sm btn-link mt-3 board-button" to={`editFooter/${value._id}`}>Edit Footer</Link>) : ''}
         
            </div>
           
            </div>
        <hr className="mt-3"/>
        
          <div className="row d-flex justify-content-center">
            
            <div className="col-8 mt-5"> 
            
            <div class="card card-primary">

            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center">Footer</h3>
             </div>
                <div className="card-body">
                <div className="form-group">
                   
                <label htmlFor="instagram">Instagram </label>
                <input className="form-control" id="instagram" value={value ? value.instagram_url : " "}/>
                </div>
                <div className="form-group">
                <label htmlFor="facebook">Facebook </label>
                <input  className="form-control" id="facebook" value={value ? value.facebook_url : " "}/>
                </div>
                <div className="form-group">
                <label htmlFor="twitter">Twiter</label>
                <input className="form-control" id="twitter" value={value? value.twitter_url : " "}/>
                </div>
                <div className="form-group">
                <label htmlFor="linkedin">Linkedin</label>
                <input  className="form-control" id="linkedin_url" value={value ? value.linkedin_url : " "} />
                </div>
                <div className="form-group">
                <label htmlFor="address">Address</label>
                <input  className="form-control" id="address" value={value ? value.address : " "}/>
                </div>
                <div className="form-group">
                <label htmlFor="email">Email</label>
                <input  className="form-control" id="email" value= {value ? value.email : " "}/>
                </div>
                </div>

                
              
            </div>
        </div>
    </div>
    </div>
    )
}

export default GetFooter