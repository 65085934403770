import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
const EditUser = () => {
    const { id } = useParams();
    const [value, setValue ] = useState({
        _id: "",
        name: "",
        surname: "",
        email: "",
        phone: "",      
    })
    const [password, setPassword] = useState("")

    const history = useHistory()

    const [error, setError] = useState({
        name: "",
        surname: "",
        phone: "",
        email: "",
        password: "",
        emailExist: ""
    })
    const handleOnChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
      };



    const onSubmit = (e) => {
        e.preventDefault()
       
        const data = {
            id: value._id,
            name: value.name,
            surname: value.surname,
            phone: value.phone,
            email: value.email,
            password: password
        }
        axios.post("/api/users/edit", data)
            .then(res => 
                { 
                     history.push(`/user`)
                })
            .catch(err => setError(err.response.data))
    }  
    useEffect(() =>{
        axios.get(`/api/users/user/${id}`)
        .then(res =>{
             setValue(res.data)
        })
        .catch(err => console.log(err))
       
        
    }, [id])
    return(
        <div>
        <Link className="btn btn-sm btn-link mt-3  board-button" to="/User" >Go back</Link>
        <hr className="mt-3"/>

          <div className="row d-flex justify-content-center">
            
            <div className="col-8 mt-5"> 
            
            <div class="card card-primary">

            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center">Edit User</h3>
              </div>
           
              <form onSubmit={onSubmit}>
              <input type="hidden" className="form-control" id="id" name="id" value={value._id} onChange={handleOnChange} />
                
                
                <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" name="name" value={value.name} onChange={handleOnChange} placeholder="Enter Name" />
                {error.name ? error.name : null}
                </div>
                <div className="form-group">
                <label htmlFor="surname">SurName</label>
                <input type="text" className="form-control" id="surname" name="surname" value={value.surname} onChange={handleOnChange} placeholder="Enter SurName" />
                {error.surname ? error.surname : null}
                </div>
                <div className="form-group">
                <label htmlFor="emai">Email</label>
                <input type="email" className="form-control" id="email" name="email"  value={value.email} onChange={handleOnChange} placeholder="Enter Email" />
                {error.email ? error.email : null}
                </div>
                
                <div className="form-group">
                <label htmlFor="Phone">Phone</label>
                <input type="text" className="form-control" id="phone" name="phone" value={value.phone} onChange={handleOnChange} placeholder="Enter phone" />
                {error.phone ? error.phone : null}
                </div>

                <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" name="password"  onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" />
                {error.password ? error.password : null}
                </div>
            
              
                
               

                <div className="card-footer d-flex justify-content-center mb-3">
                  <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
                </div>
              </form>
            </div>
        </div>
    </div>
    </div>
    )

}

export default EditUser