import React from 'react';
import './App.css';

import { BrowserRouter as Router,  Switch} from 'react-router-dom';

import AppRoute from './AppRoute';
import PrivateRoute from './PrivateRoute';

import DashboardLayout from './components/layouts/DashboardLayout';
import MainLayouts from './components/MainLayouts/MainLayouts';
import createHistory from 'history/browser';


import Login from './components/auth/Login';
import Register from './components/auth/Register';
import GetUser from './components/auth/GetUser';
import EditUser from './components/auth/EditUser';
import AddBoard from './components/board/AddBoard';
import GetBoard from './components/board/GetBoard';
import EditBoard from './components/board/EditBoard';
import AddManagment from './components/managment/AddManagment';
import GetManagment from './components/managment/GetManagment';
import EditManagment from './components/managment/EditManagment';
import GetMembers from './components/members/GetMembers';
import AddMembers from './components/members/AddMembers';
import EditMembers from './components/members/EditMembers';
import GetProject from './components/project/GetProject';
import AddProject from './components/project/AddProject';
import EditProject from './components/project/EditProject';
import EditFooter from './components/footer/EditFooter';
import GetFooter from './components/footer/GetFooter';

import GetMision from './components/mision/GetMision';
import EditMision from './components/mision/EditMision';
import GetBuletin from './components/buletin/GetBuletin';
import AddBuletin from './components/buletin/AddBuletin';
import EditBuletin from './components/buletin/EditBuletin';

import AddNew from './components/news/AddNew';
import EditNew from './components/news/EditNew';
import GetNew from './components/news/GetNew';
import EditConnection from './components/connection/EditConnection';
import GetConnection from './components/connection/GetConnection';
import AddPdf from './components/pdf/AddPdf';

import GetFiles from './components/files/GetFiles';
import AddFiles from './components/files/AddFiles';

// Load Pages
import Home from './pages/home/Home';
import Members from './pages/members/Members';
import Projects from './pages/project/Projects';
import Board from './pages/board/Board';
import Mision from './pages/mision/Mison';
import News from './pages/news/News';
import ShowNews from './pages/news/ShowNews';
import Connection from './pages/connection/Connection'
import Buletin from './pages/buletin/Buletin';
import GetBanner from './components/banner/GetBanner';
import AddBanner from './components/banner/AddBanner';
const App = () =>{
 
  
  return (
   <div class="wrapper">
     
        
        <Router history={createHistory}> 
        
          <Switch>
            {/*Load Route pages */}
            <AppRoute exact path="/" component ={Home} layout={MainLayouts} />
            <AppRoute path="/members" component={Members} layout={MainLayouts} />
            <AppRoute path="/project" component={Projects} layout={MainLayouts} />
            <AppRoute path="/board" component={Board} layout={MainLayouts} />
            <AppRoute path="/mision" component={Mision} layout={MainLayouts} />
            <AppRoute path="/news" component={News} layout={MainLayouts} />
            <AppRoute path="/showNews/:id" component={ShowNews} layout={MainLayouts}/>
            <AppRoute path="/login" component={Login} layout={MainLayouts}/>
            <AppRoute path="/connection" component={Connection} layout={MainLayouts}/>
            <AppRoute path="/buletin" component={Buletin} layout={MainLayouts}/> 
            {/**end route pages */}



            
            <PrivateRoute path="/register" component={Register} layout={DashboardLayout}/>
            <PrivateRoute path="/user" component={GetUser} layout={DashboardLayout} />
            <PrivateRoute path="/edit/user/:id" component={EditUser} layout={DashboardLayout} />
            <PrivateRoute path="/addBoard" component={AddBoard} layout={DashboardLayout} />
            <PrivateRoute path="/getBoard" component={GetBoard} layout={DashboardLayout} />
            <PrivateRoute path="/editBoard/:id" component={EditBoard} layout={DashboardLayout} />
            
            <PrivateRoute path="/addManagment" component={AddManagment} layout={DashboardLayout} />
            <PrivateRoute path="/getManagment" component={GetManagment} layout={DashboardLayout} />
            <PrivateRoute path="/editMangment/:id" component={EditManagment} layout={DashboardLayout}  />


            <PrivateRoute path="/getMembers" component={GetMembers} layout={DashboardLayout} />
            <PrivateRoute path="/addMembers" component ={AddMembers} layout={DashboardLayout} />
            <PrivateRoute path="/editMembers/:id" component={EditMembers} layout={DashboardLayout} />

            <PrivateRoute path ="/getProject" component={GetProject} layout={DashboardLayout} />
            <PrivateRoute path= "/addProject" component={AddProject} layout={DashboardLayout} />
            <PrivateRoute path="/editProject/:id" component={EditProject} layout={DashboardLayout} />

            <PrivateRoute path ="/getFooter" component={GetFooter} layout={DashboardLayout}  />
            <PrivateRoute path ="/editFooter/:id" component={EditFooter} layout={DashboardLayout} />

       

            <PrivateRoute path="/getMision" component={GetMision} layout={DashboardLayout} />
            <PrivateRoute path="/editMision/:id" component={EditMision} layout={DashboardLayout} />

            <PrivateRoute  path="/getBuletin" component={GetBuletin} layout={DashboardLayout} />
            <PrivateRoute  path="/AddBuletin" component={AddBuletin} layout={DashboardLayout} />
            <PrivateRoute  path="/editBuletin/:id" component={EditBuletin} layout={DashboardLayout}  />


            <PrivateRoute  path="/getNew" component={GetNew} layout={DashboardLayout}  />
            <PrivateRoute  path="/addNew" component={AddNew} layout={DashboardLayout} />
            <PrivateRoute  path="/editNew/:id" component={EditNew} layout={DashboardLayout} />


            <PrivateRoute  path="/getConnection" component={GetConnection} layout={DashboardLayout} />
            <PrivateRoute  path="/editConnection/:id" component={EditConnection} layout={DashboardLayout} />
            

            <PrivateRoute  path="/addPdf" component={AddPdf} layout={DashboardLayout} />

            <PrivateRoute  path="/GetFiles" component={GetFiles} layout={DashboardLayout} />
            <PrivateRoute  path="/AddFiles" component={AddFiles} layout={DashboardLayout}/>

            <PrivateRoute  path="/GetBanner" component={GetBanner} layout={DashboardLayout}/>
            <PrivateRoute  path="/AddBanner" component={AddBanner} layout={DashboardLayout}/>
            
            </Switch>
         </Router>
   

    </div>
    
  );
}

export default App;
