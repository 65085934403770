import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


const GetBanner = () => {
    const [value, setValue] = useState([]);


    useEffect( () => {
        axios.get("/api/banner")
            .then(res => setValue(res.data))
            .catch(err => console.log(err))
    }, [])

    const deleteBannerById = (id) => {
        axios.delete(`/api/banner/delete/${id}`)
                .then(res => {
                    window.location.reload()
                })
                .catch(err => console.log(err))
    }
    return(
        <div>

        <Link className="btn btn-sm btn-link mt-3  board-button" to="/AddBanner" >Add Slider</Link>
        <hr className="mt-3"/>
    <div className="container">
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col">View Photo Alb</th>
                    <th scope="col">View Photo Eng</th>
                    <th scope="col">URL</th>
                    <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                
                {value.map(data => (
                <tr key={data._id}>    
                 <td><a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api/${data.photo}`}>View Photo Alb</a></td>
                 <td><a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api/${data.photo_en}`}>View Photo Eng</a></td>
                <td>{data.link}</td>
                
                
                <td>
                    <button className="btn btn-sm btn-danger" type="button" onClick={() => deleteBannerById(data._id)}>Delete</button>
                </td>
                </tr>
                
                ))} 
                    
                </tbody>
            </table>
            

            </div>
       
    </div>
    )
}


export default GetBanner