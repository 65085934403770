import React, { useState } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
const AddBoard = () => {
    const history = useHistory();

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [positionOnCsr, setPositionOnCsr] = useState("");
    const [positionOnCsrEn, setPositionOnCsrEn] = useState("");
    const [positionJob, setPositionJob] = useState("");
    const [positionJobEn, setPositionJobEn] = useState("");
    const [photo, setPhoto] = useState();

    const [error, setError] = useState({
        name: "",
        surname: "",
        position_on_csr: "",
        position_job: "",
        position_on_csr_en: "",
        position_job_en:"",
    })

    const onSubmit =(e)=>{
        
        e.preventDefault();

        const data = new FormData();
        data.append("name", name);
        data.append("surname", surname);
        data.append("position_on_csr", positionOnCsr);
        data.append("position_on_csr_en", positionOnCsrEn);
        data.append("position_job", positionJob);
        data.append("position_job_en", positionJobEn);
        data.append("boardImage", photo);

        axios.post("/api/board", data, { 
            headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
                history.push("/getBoard")
            })
            .catch(err => setError(err.response.data))
    }
    return(
        <div>
            <Link className="btn  btn-link board-button mt-3" to="/getBoard">Go Back</Link>
            <hr />
          <div className="row d-flex justify-content-center">
            
            <div className="col-8"> 
            
            <div class="card">

            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center">Add  Board Member</h3>
              </div>
          
              <form onSubmit={onSubmit}>
              
              
                
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" name="name" onChange={e => setName(e.target.value)} placeholder="Enter name" />
                    {error.name ? error.name : null}
                  </div>
                  <div className="form-group">
                    <label for="surname">Surname</label>
                    <input type="text" className="form-control" id="surname" name="surname" onChange={e => setSurname(e.target.value)} placeholder="Enter surname" />
                    {error.surname ? error.surname : null}
                  </div>
                  <div className="form-group">
                    <label for="positiOnCsr">Position at CSR ALB </label>
                    <input type="text" className="form-control" id="positiOnCsr" name="positiOnCsr" onChange={e => setPositionOnCsr(e.target.value)} placeholder="Enter Position" />
                    {error.position_on_csr ? error.position_on_csr : null}
                  </div>
                  <div className="form-group">
                    <label for="positiOnCsrEn">Position at CSR ENG</label>
                    <input type="text" className="form-control" id="positiOnCsrEn" name="positiOnCsrEn" onChange={e => setPositionOnCsrEn(e.target.value)} placeholder="Enter Positon in english" />
                    {error.position_job ? error.position_job : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="positionJob">Job Position ALB </label>
                    <input type="text" className="form-control" id="positionJob" name="positionJob" onChange={e => setPositionJob(e.target.value)} placeholder="Enter Positon Job" />
                    {error.position_on_csr_en ? error.position_on_csr_en : null}
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="positionJobEn">Job Position ENG</label>
                    <input type="text" className="form-control" id="positionJobEn" name="positionJobEn" onChange={e => setPositionJobEn(e.target.value)}  placeholder="Enter Positon Job in english" />
                    {error.position_job_en ? error.position_job_en : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="photo">Photo</label>
                    <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  placeholder="Enter Positon Job in english" />
                  </div>



                </div>

                <div className="card-footer d-flex justify-content-center mb-3">
                  <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
                </div>
              </form>
            </div>
        </div>
    </div>
    </div>
              
     
    )
}

export default AddBoard