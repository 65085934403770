import React, {useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom'; 
import axios from 'axios';

const EditFooter = () => {
    const { id } = useParams()
    const history = useHistory()

    const [value, setValue] = useState({
        _id: "",
        instagram_url: "",
        facebook_url: "",
        twitter_url: "",
        linkedin_url: "",
        address: "",
        email: ""
    })


    const [error, setError] = useState({
        instagram_url: "",
        facebook_url: "",
        twitter_url: "",
        linkedin_url: "",
        address: "",
        email: ""
    })

    const onSubmit = (e) => {
        e.preventDefault()

        const data = {
            id: value._id,
            instagram_url: value.instagram_url,
            facebook_url: value.facebook_url,
            twitter_url: value.twitter_url,
            linkedin_url:value.linkedin_url,
            address: value.address,
            email: value.email
        }
        axios.post("/api/footer/edit", data)
            .then(res => {
                history.push("/getFooter")
            })
            .catch(err => setError(err.response.data))
    }

    useEffect( () => {
        axios.get(`/api/footer/show/${id}`)
            .then(res => {
                setValue(res.data)
            })
            .catch(err => console.log(err))
    }, [id])

    const handleOnChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
      };

    return(
        <div>
        <Link className="btn btn-sm btn-link mt-3 board-button" to="/getFooter">Go Back</Link>
        <hr className="mt-3"/>
        
          <div className="row d-flex justify-content-center">
            
            <div className="col-8 mt-5"> 
            <form onSubmit={onSubmit}> 
            <div class="card card-primary">
            
            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center" >Edit Footer</h3>
             </div>
                <div className="card-body">
                <input type="hidden" name="_id" id="_id" value={value._id} onChange={handleOnChange}/>
                <div className="form-group">
                <label htmlFor="instagram_url">Instagram url </label>
                <input type="text" className="form-control" id="instagram_url" name="instagram_url" value={value.instagram_url} onChange={handleOnChange} placeholder="Instagram"/>
                {error.instagram_url ? error.instagram_url : null}
                </div>
                <div className="form-group">
                <label htmlFor="facebook_url">Facebook </label>
                <input type="text" className="form-control" id="facebook_url" name="facebook_url" value={value.facebook_url} onChange={handleOnChange} placeholder="Facebook"/>
                {error.facebook_url ? error.facebook_url : null}
                </div>
                <div className="form-group">
                <label htmlFor="twitter_url">Twiter</label>
                <input type="text" className="form-control" id="twitter_url"  name="twitter_url" value={value.twitter_url} onChange={handleOnChange} placeholder="Twitter"/>
                {error.twitter_url ? error.twitter_url : null}
                </div>
                <div className="form-group">
                <label htmlFor="linkedin_url">Linkedin</label>
                <input type="text" className="form-control" id="linkedin_url"   name="linkedin_url" value={value.linkedin_url} onChange={handleOnChange} placeholder="Youtube"/>
                {error.linkedin_url ? error.linkedin_url : null}
                </div>
                <div className="form-group">
                <label htmlFor="address">Address</label>
                <input type="text" className="form-control" id="address" name="address" value={value.address} onChange={handleOnChange} placeholder="Address"/>
                {error.address ? error.address : null}
                </div>
                <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control" id="email" name="email" value={value.email} onChange={handleOnChange} placeholder="Email"/>
                {error.email ? error.instagram_url : null}
                </div>

                <div className="card-footer d-flex justify-content-center mb-3">
                  <button type="submit" className="btn  " style={{background: '#29B473', color:"white"}}>Submit</button>
                </div>

              
                </div>
            </div>
            </form> 
        </div>
    </div>
    </div>
    )
}

export default  EditFooter