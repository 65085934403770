import React , {useState }from 'react';
import axios from 'axios';
import {  useHistory } from 'react-router-dom';


const AddFiles = () => {
    const history = useHistory()

    const [name, setName] = useState("")
    const [files, setFiles] = useState()
    const [filesEn, setFilesEn] = useState()
    const [error, setError] = useState({})

    const onSubmit = (e) =>{
        e.preventDefault()
     
        const data = new FormData()
        data.append("name", name)
        data.append("files", files)
        data.append("files_en", filesEn)

        axios.post("/api/files", data, { 
            headers: { "Content-Type": "multipart/form-data" }})
             .then(res => {
                history.push("/GetFiles")
             })
             .catch(err => setError(err.response.data))
    }
    return(
        <div className="row d-flex justify-content-center">
            
        <div className="col-8  mt-5"> 
        
        <div class="card">

        <div class="card-header" style={{background: '#29B473', color:"white"}}>
            <h3 class="text-center">Add  Files</h3>
          </div>
      
                <form onSubmit={onSubmit}>
                
                
                    
                    <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control" id="name" name="name"   onChange={e => setName(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="files">Files Alb</label>
                        <input type="file" className="form-control" id="files" name="files"   onChange={e => setFiles(e.target.files[0])}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="filesEn">Files Eng</label>
                        <input type="file" className="form-control" id="filesEn" name="filesEn"  onChange={e => setFilesEn(e.target.files[0])}/>
                    </div>
                    



                    </div>

                    <div className="card-footer d-flex justify-content-center mb-3">
                    <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}

export default AddFiles;