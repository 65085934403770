import React from 'react';


const Footer = () => {
    return(
        <div>
        <footer className="main-footer">
           
        </footer>
        </div>
    )
}

export default Footer;