import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import  axios from 'axios';
import "./Buletin.css"

const GetBuletin = () => {
    const [values, setValues] = useState([])
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(0)

    const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

    useEffect(() => {
        axios.get(`/api/buletin?page=${pageNumber}`)
             .then(res =>{
                 setValues(res.data.buletin)
                 setNumberOfPages(res.data.totalPages)
             })
             .catch(err => console.log(err))   
    }, [pageNumber])

    const gotoPrevious = () => {
        setPageNumber(Math.max(0, pageNumber - 1));
    };

    const gotoNext = () => {
        setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
    };


    const onDeleteBuletinById = (id) => {
        axios.delete(`/api/buletin/delete/${id}`)
             .then(res => {
                window.location.reload()
             })
             .catch(err => console.log(err))
    }
    

    return(
        <div>
            <Link className="btn btn-sm btn-link mt-3  board-button" to="/addBuletin" >Add Newsletter</Link>
            <hr />
          
            {values.map(data => (         
            <div key={data._id}>
            <div className="row mt-2" >
            <div className="col-md-5 col-sm-12 col-lg-5 d-flex justify-content-center href_buletin">
                <a   className=""  href={`http://www.csrkosovo.org:5000/${data.buletin}`}>{data.name}</a>
            </div>
            <div className="col-md-5 col-sm-12 col-lg-5 d-flex justify-content-center href_buletin">
               <a className="" href={`http://www.csrkosovo.org:5000/${data.buletin_en}`}>{data.name_english}</a> 
            </div>
            <div className="col-md-2 col-sm-12 col-lg-2 d-flex justify-content-center href_buletin">
                    <Link className="btn btn-sm btn-link" to={`editBuletin/${data._id}`}>Edit</Link>
                    <button className="btn btn-sm btn-danger" onClick={() => onDeleteBuletinById(data._id)}>Delete</button>
            </div>
            </div>
            </div>
            ))}
            <hr />
            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li className="page-item"><button className="page-link" onClick={gotoPrevious}>Previous</button></li>
                    {pages.map((pageIndex) => (
                    <li className="page-item" ><button  className="page-link" key={pageIndex} onClick={() => setPageNumber(pageIndex)}>
                    {pageIndex + 1}
                    </button></li>
                    ))}
                    <li className="page-item"> <button  className="page-link" onClick={gotoNext}>Next</button></li>
                </ul>
            </nav>
            
        </div>
    )
}

export default GetBuletin;