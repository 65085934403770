import React, { useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditNew = () => {
    const { id } = useParams()
    const history = useHistory();
    const [desc, setDesc] = useState("")
    const [descEn, setDescEn] = useState("")
    const [value, setValue] = useState({
        _id: "",
        name: "",
        description: "",
        name_en: "",
        description_en: "",
        data_news: "",
        image: ""
    })
    const [photo, setPhoto] = useState()
    const [error, setError] = useState({
        name: "",
        description: "",
        name_en: "",
        description_en: "",
        data_news: ""
    })

    const handleOnChnage = (e) =>{
        setValue({ ...value, [e.target.name]: e.target.value });
    }
    useEffect( () => {
                axios.get(`/api/news/show/${id}`)
                        .then(res => {
                            console.log(res.data)
                            setValue(res.data)
                        })
                        .catch(err => setError(err.response.data))
    },[id])
    const onSubmit = (e) => {
        e.preventDefault()

        const data = new FormData()
        data.append("id", value._id)
        data.append("name", value.name)
        data.append("description", desc)
        data.append("name_en", value.name_en)
        data.append("description_en", descEn)
        data.append("data_news", value.data)
        data.append("newsImage", photo)


        axios.post("/api/news/edit", data, { 
            headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
                history.push(`/getNew`)
            })
            .catch(err => console.log(err))
    }
    return(
        <div>
        <Link className="btn  btn-link board-button mt-3" to="/getNew">Go Back</Link>
        <hr className="mt-3"/>
    
      <div className="row d-flex justify-content-center">
        
        <div className="col-8 mt-5"> 
        <form onSubmit={onSubmit}>
        <div class="card ">
       
        <div class="card-header" style={{background: '#29B473', color:"white"}}>
            <h3 class="text-center">Edit News</h3>
         </div>
            <div className="card-body">
            <div className="form-group">

            <input name="id" type="hidden" value={value._id} onChange={handleOnChnage}/>  
            <label htmlFor="name">Name </label>
            <input className="form-control" id="name" name="name" value={value.name}onChange={handleOnChnage} />
            {error.name ? error.name : ''}
            </div>
            
            <div className="form-group mt-2">
            <label htmlFor="descriptionEn">Body English</label>
              <CKEditor 
                editor={ ClassicEditor }
                 data={value.description}
               
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    setDesc(data)
                } }
                onBlur={ ( event, editor ) => {
                } }
                onFocus={ ( event, editor ) => {
                } 
                }
            />
            {error.description ? error.description : ''}
            </div>


            <div>
            <label htmlFor="name_en">Name English </label>
            <input className="form-control" id="name_en" name="name_en" value={value.name_en}onChange={handleOnChnage} />
            {error.name_en ? error.name_en : ''}
            </div>
          
            
            <div className="form-group mt-2">
            <label htmlFor="descriptionEn">Body English</label>
              <CKEditor 
                editor={ ClassicEditor }
                 data={value.description_en}
               
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    setDescEn(data)
                } }
                onBlur={ ( event, editor ) => {
                } }
                onFocus={ ( event, editor ) => {
                } 
                }
            />
            {error.description_en ? error.description_en : ''}
            </div>

            <div className="form-group">
            <label htmlFor="datas">Date</label>
            <input className="form-control" id="data" name="data" value={value.data_news} onChange={handleOnChnage}/>
            {error.date_news ? error.date_news : ''}
            </div>
           
            <div className="form-group">
            <label htmlFor="photo">Photo</label>
            <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  placeholder="Enter Positon Job in english" />
            <a className="btn btn-sm btn-link"href={`https://www.csrkosovo.org/api/${value.image}`}>View photo</a>
            </div>
            <div className="card-footer d-flex justify-content-center mb-3">
            <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
            </div>
            </div>
         
        </div>
        </form>
    </div>
 </div>
 </div>
    )
}

export default EditNew