import React from 'react';
import './Project.css';
const Project = ({ project, lang }) => {
    return(
        <div>
          
            <div className="row " key={project._id}>
                <div className="col-lg-7 col-sm-12 d-flex justify-content-center mt-3">
                    <div className="align-self-center">
                    <a target="_blank" href={project.web_link}>
                    <img src={`https://www.csrkosovo.org/api/${project.image}`} className="img-fluid img mb-4" alt="..."/> 
                    </a>
                    </div>
                        
                        
                </div>
                 <div className="col-lg-4 col-sm-12">
                  
                     <p className="name__project">{project.name}</p>
                     
                     <a className="href__proejct" target="_blank" href={project.web_link}>{project.name_link}</a>
                     <p className="text__project ">
                         {lang === 'al' ? project.description : project.description_en}
                     </p>
                     
                     
                 </div>     
            </div>
              
            <hr className="hr_project"/>
        </div>
    )
}

export default  Project