import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddPdf = () => {
    const [id, setId] = useState("")
    const [statutAlb, setStatutAlb] = useState()
    const [strategyAlb, setStrategyAlb] = useState()
    const [statutEng, setStatutEng] = useState()
    const [strategyEng, setStrategyEng] = useState()
    const [docsAl, setDocsAl] = useState()
    const [docsEn, setDocsEn] = useState()

    const [value, setValue] = useState({
        _id: "",
        statut_al:"",
        strategy_al: "",
        statut_en: "",
        strategy_eng: "",
        docs: "",
        docs_en: ""
    })
    useEffect(() => {
        axios.get("/api/pdf")
            .then(res => {
                if(res.data.length !== 0){
                
                setValue(res.data[0])
                }
            })
            .catch(err => console.log(err))
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()

        const data = new FormData();
        data.append("id", value._id)
        data.append("statut_al", statutAlb);
        data.append("strategy_al", strategyAlb);
        data.append("statut_en", statutEng);
        data.append("strategy_en", strategyEng);
        data.append("docs_al", docsAl);
        data.append("docs_en", docsEn);
    
        axios.post("/api/pdf/edit", data, { 
            headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
               
                window.location.reload()
            })
            .catch(err => console.log(err))
    }
    return(
        <div className="row d-flex justify-content-center">
            
        <div className="col-8  mt-5"> 
        
        <div class="card">

        <div class="card-header" style={{background: '#29B473', color:"white"}}>
            <h3 class="text-center">Add  Replace/Files</h3>
          </div>
      
                <form onSubmit={onSubmit}>
                
                
                    
                    <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="statut_alb">By-Laws Albanian</label>
                        <input type="file" className="form-control" id="statut_alb" name="statut_alb"   onChange={e => setStatutAlb(e.target.files[0])}/>
                        <a href={`https://www.csrkosovo.org/api/${value.statut_al}`}>View</a>
                    </div>
                    <div className="form-group">
                        <label htmlFor="statut_eng">By-Laws English</label>
                        <input type="file" className="form-control" id="statut_eng" name="statut_eng"   onChange={e => setStatutEng(e.target.files[0])}/>
                        <a href={`https://www.csrkosovo.org/api/${value.statut_en}`}>View</a>
                    </div>
                    <div className="form-group">
                        <label htmlFor="strategy_alb">Strategy Albanian</label>
                        <input type="file" className="form-control" id="strategy_alb" name="strategy_alb"  onChange={e => setStrategyAlb(e.target.files[0])}/>
                        <a href={`https://www.csrkosovo.org/api/${value.strategy_al}`}>View</a>
                    </div>
                    <div className="form-group">
                        <label htmlFor="strategy_en">Strategy English</label>
                        <input type="file" className="form-control" id="strategy_en" name="strategy_en" onChange={e => setStrategyEng(e.target.files[0])}  />
                        <a href={`https://www.csrkosovo.org/api/${value.strategy_en}`}>View</a>
                    </div>

                    <div className="form-group">
                        <label htmlFor="docsAl">Membership Form Albanian</label>
                        <input type="file" className="form-control" id="docsAl" name="docsAl"  onChange={e => setDocsAl(e.target.files[0])}/>
                        <a href={`https://www.csrkosovo.org/api/${value.docs}`}>View</a>
                    </div>
                    <div className="form-group">
                        <label htmlFor="docsEn">Membership Form English</label>
                        <input type="file" className="form-control" id="docsEn" name="docsEn" onChange={e => setDocsEn(e.target.files[0])}  />
                        <a href={`https://www.csrkosovo.org/api/${value.docs_en}`}>View</a>
                    </div>



                    </div>

                    <div className="card-footer d-flex justify-content-center mb-3">
                    <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}


export default AddPdf