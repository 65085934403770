import React, { useState } from 'react';
import axios from 'axios';
import { Link , useHistory } from 'react-router-dom';
const AddBanner = () => {
    const history = useHistory();

    
    const [link, setLink] = useState("")
    const [photo, setPhoto] = useState()
    const [photoEn, setPhotoEn] = useState()

    const [error, setError] = useState({})

    const onSubmit = (e) => {
        e.preventDefault()

        const data = new  FormData()
       
        data.append("link", link)
        data.append("photo", photo)
        data.append("photo_en", photoEn)

        axios.post("/api/banner", data, { 
            headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
                history.push("/GetBanner")
            })
            .catch(err => setError(err.response.data))
    }
    return(
        <div>
        <Link className="btn  btn-link board-button mt-3" to="/GetBanner">Go Back</Link>
        <hr />
      <div className="row d-flex justify-content-center">
        
        <div className="col-8"> 
        
        <div class="card">

        <div class="card-header" style={{background: '#29B473', color:"white"}}>
            <h3 class="text-center">Add  Slider</h3>
          </div>
      
          <form onSubmit={onSubmit}>
          
          
            
            <div className="card-body">
             
              <div className="form-group">
                <label htmlFor="photo">Photo Alb</label>
                <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  />
              </div>
              <div className="form-group">
                <label htmlFor="photoEn">Photo Eng</label>
                <input type="file" className="form-control" id="photoEn" name="photoEn" onChange={e => setPhotoEn(e.target.files[0])}  />
              </div>


              <div className="form-group">
                <label for="link">URL</label>
                <input type="text" className="form-control" id="link" name="link" onChange={e => setLink(e.target.value)} placeholder="Enter Link" />
                {error.link ? error.link : null}
              </div>

            </div>

            <div className="card-footer d-flex justify-content-center mb-3">
              <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
            </div>
          </form>
        </div>
    </div>
</div>
</div>
    )
}


export default AddBanner