import React, { useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditConnection = () =>{
    const { id } = useParams() 
    const history = useHistory()
    const [desc, setDesc] = useState("")
    const [descEn, setDescEn] = useState("")

    const [value, setValue] = useState({
        _id: "",
        title: "",
        description: "",
        title_en: "",
        description_en: "",
        image: ""
    })


    const [photo, setPhoto] = useState()

    const [error, setError] = useState({
        title: "",
        description: "",
        title_en: "",
        description_en: "",
        image: '',
    })

    useEffect(() => {
        axios.get(`/api/conn/show/${id}`)
                .then(res => {
                    setValue(res.data)
                })
                .catch(err => console.log(err))
    },[id])

    const handleOnChange = (e) =>{
        setValue({ ...value, [e.target.name]: e.target.value });
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const data = new FormData()
        data.append('id', value._id)
        data.append('title', value.title)
        data.append("description", desc)
        data.append("title_en", value.title_en)
        data.append("description_en", descEn)
        data.append("connImage", photo)

        axios.post("/api/conn/edit", data, { 
            headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
                history.push("/getConnection")
            })
            .catch(err => setError(err.response.data))
    }

  
    return(
        <div>
        <Link className="btn  btn-link board-button mt-3" to="/getConnection">Go Back</Link>
        <hr />
      <div className="row d-flex justify-content-center">
        
        <div className="col-8"> 
        <div class="card">

        <div class="card-header" style={{background: '#29B473', color:"white"}}>
            <h3 class="text-center">Edit About</h3>
          </div>
         
          <form  onSubmit={onSubmit}>
            
            <div className="card-body">
            <input type="hidden" className="form-control" id="id" name="id" value={value._id} onChange={handleOnChange} />

            <div className="form-group">
            <label htmlFor="title">Title</label>
            <input type="text" className="form-control" id="title" name="title" value={value.title} onChange={handleOnChange}  />
            {error.title ? error.title : null}
            </div>
            <div className="form-group mt-2">
            <label htmlFor="description">Body</label>
              <CKEditor 
                editor={ ClassicEditor }
                 data={value.description}
               
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    setDesc(data)
                } }
                onBlur={ ( event, editor ) => {
                } }
                onFocus={ ( event, editor ) => {
                } 
                }
            />
             {error.description ? error.description : null}
            </div>
           
            <div className="form-group">
            <label htmlFor="title_en">Title English</label>
            <input type="text" className="form-control" id="title_en" name="title_en" value={value.title_en} onChange={handleOnChange}  />
            {error.title_en ? error.title_en : null}
            </div>

              
            <div className="form-group mt-2">
            <label htmlFor="description">Body English</label>
              <CKEditor 
                editor={ ClassicEditor }
                 data={value.description_en}
               
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    setDescEn(data)
                } }
                onBlur={ ( event, editor ) => {
                } }
                onFocus={ ( event, editor ) => {
                } 
                }
            />
             {error.description_en ? error.description_en : null}
            </div>

            <div className="form-group">
                <label htmlFor="photo">Photo</label>
                <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  />
                <a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api/${value.image}`}>View Photo</a>
            </div>


            </div>

            <div className="card-footer d-flex justify-content-center mb-3">
              <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
            </div>
          </form>
        </div>
    </div>
</div>
</div>
    )
}

export default EditConnection