import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo_footer from '../../../img/Logo_footer.png'
import { Link } from 'react-router-dom';
import "./Footer.css"


const Footer = () => {
    const lang = localStorage.getItem("lang");
    const [footer, setFooter] = useState({})
    const [pdf, setPdf] = useState({})
    useEffect( () => {
        axios.get("/api/footer")
            .then(res => {
                if(res.data.length !== 0){
                setFooter(res.data[0])
                }
            })
            .catch(err => console.log(err))
    },
    [])

    useEffect(() => {
        axios.get("/api/pdf")
              .then(res => {
                if(res.data.length !== 0){
                  setPdf(res.data[0])
                }
              })
              .catch(err => console.log(err))
      }, [])
    const renderFooter = (footer) => {
            return(
            <div className="d-flex justify-content-between">
                    <a href={footer.facebook_url} target="_blank">
                    <i className="fab fa-facebook social__links fa-2x"></i>
                    </a>
                    <a href={footer.instagram_url} target="_blank">
                    <i className="fab fa-instagram social__links fa-2x" ></i>
                    </a>
                    <a href={footer.twitter_url} target="_blank">
                    <i className="fab fa-twitter social__links fa-2x"></i>
                    </a>
                    <a href={footer.linkedin_url} target="_blank">
                    <i className="fab fa-linkedin social__links fa-2x"></i>
                    </a>    
            </div>
            )
            
    }
    return(
        
        <footer className="page-footer  indigo">

      
          <div className="row">
            <div className="col-md-3 col-sm-12 mt-3 text-center align-self-center">
                    <img src={Logo_footer} className="img-fluid logo__footer" alt="..."/>
            </div>
           

            <hr class="clearfix w-100 d-md-none" />

            <div class="col-md-3 col-sm-12 mt-3 text-center align-self-center">
                    <div class="d-flex flex-row ">
                        <i class="fas fa-map-marker-alt mt-2"></i>
                        <p className="address__footer mt-1">{footer.address}</p>
                    </div>
                    <div class="d-flex flex-row ">
                        <i class="far fa-envelope mt-2"></i>
                        <p className="email__footer mt-1">{footer.email}</p>
                    </div>
              </div>

         <hr class="clearfix w-100 d-md-none" />

        <div class="col-md-3 col-sm-12 mt-5 ">

            <div className="row">
                <div className="col-md-6 col-sm-12">
                <ul class="list-unstyled">
                <li>
                <Link to={'/mision'} className="link__footer">{lang === 'al' ? 'Misioni dhe Vizioni' : 'Mission and Vision'}</Link>
                </li>
                <li>
                <Link to="/board" className="link__footer">{lang === 'al' ? 'Bordi dhe Menaxhmenti' : 'Board and Management'}</Link>
                </li>
                <li>
                {lang === 'al' ? (<a className="link__footer" href={`http://www.csrkosovo.org:5000/${pdf.statut_al}`}>Statuti i Rrjetit CSR Kosova</a>) : (<a className="link__footer" href={`http://www.csrkosovo.org:5000/${pdf.statut_en}`}>By-Laws</a>)}
                </li>
                <li>
                {lang === 'al' ? (<a className="link__footer" href={`http://www.csrkosovo.org:5000/${pdf.strategy_al}`}>Strategjia 2021-2025</a>) : (<a className="link__footer" href={`http://www.csrkosovo.org:5000/${pdf.strategy_en}`}>Strategy 2021-2025</a>) }
                </li>
                </ul>
            </div>
            <div className="col-md-6 col-sm-12">
                <ul class="list-unstyled">
                <li>
                <Link to="/project" className="link__footer">{lang === 'al' ? 'Projektet' : 'Projects'}</Link>
                </li>
                <li>
                <Link to="/members" className="link__footer">{lang === 'al' ? 'Anëtarët' : 'Members'}</Link>
                </li>
                <li>
                <Link to="/news" className="link__footer">{lang === 'al' ? 'Lajme dhe Ngjarje' : 'News and Events'}</Link>
                </li>
                </ul>
            </div>
            </div>

         </div>
         <div className="col-md-3 d-flex justify-content-center text-center align-self-center">
             
           
            {renderFooter(footer)}

        

         </div>

            
          </div>
      
     
      
      </footer>
        
    )
}

export default Footer