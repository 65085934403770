import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


const GetUser = () => {
    const [values, setValues] = useState([])
    
    useEffect(() => {
        axios.get("/api/users/users")
        .then(res => 
            {
                setValues(res.data)
            })
        .catch(err => console.log(err))
      
    },[])

    const deleteUserById = (id) => {
            axios.delete(`/api/users/delete/${id}`)
                .then(res => window.location.reload())
                .catch(err => console.log(err))
        }

   
    return(
        <div>
        <Link className="btn btn-sm btn-link mt-3  board-button" to="/register" >Add New User</Link>
        <hr className="mt-3"/>

        <div className="container">
        <table className="table">
            <thead>
                <tr>
                <th scope="col">Name</th>
                <th scope="col">Surname </th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
            
            {values.map(data => (
            <tr key={data._id}>    
            <td>{data.name}</td>
            <td>{data.surname}</td>
            <td>{data.email}</td>
            <td>{data.phone}</td>
            <td>
                <Link className="btn btn-sm btn-info" to={`/edit/user/${data._id}`}>Edit</Link>
                <button className="btn btn-sm btn-danger ml-5" type="button" onClick={() => deleteUserById(data._id)} >Delete</button>
            </td>
            </tr>
            
            ))} 
                 
            </tbody>
        </table>
        

        </div>
        </div>
    )
}
export default GetUser