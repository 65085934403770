import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';


const AddBuletin = () => {
    const history = useHistory()

    const [name, setName] = useState("")
    const [nameEnglish, setNameEnglish] = useState("")
    const [buletin, setBuletin] = useState()
    const [buletinEn, setBuletinEn] = useState()

    const [error, setError] = useState({
            name: "",
            name_english: "",
            file:""
    })

    const onSubmit = (e) => {
        e.preventDefault()

        const data = new FormData()
        data.append("name", name)
        data.append("name_english", nameEnglish)
        data.append("buletin", buletin)
        data.append("buletin_en", buletinEn)


        axios.post("/api/buletin", data, { 
            headers: { "Content-Type": "multipart/form-data" }})
             .then(res => {
                history.push("/getBuletin")
             })
             .catch(err => setError(err.response.data))
    }
    return(
       <div>
       <Link className="btn  btn-link board-button mt-3" to="/getBuletin">Go Back</Link>
       <hr className="mt-3"/>
   
     <div className="row d-flex justify-content-center">
       
       <div className="col-8 mt-5"> 
       {error.file ? (<div className="alert alert-info text-center mt-3" role="alert">{error.file}</div>) : null}
       <form onSubmit={onSubmit}>
       <div class="card card-primary">
      
       <div class="card-header" style={{background: '#29B473', color:"white"}}>
           <h3 class="text-center">Add Newsletter</h3>
        </div>
           <div className="card-body">
           <div className="form-group">
              
           <label htmlFor="name">Name </label>
           <input className="form-control" id="name" name="name" onChange={e => setName(e.target.value)} placeholder="Name"/>
           {error.name ? error.name : ''}
           </div>
           <div className="form-group">
           <label htmlFor="nameEnglish">Name English</label>
           <input  className="form-control" id="nameEnglish" name="nameEnglish"  onChange={e => setNameEnglish(e.target.value)} placeholder="Name English"/>
           {error.name_english ? error.name_english : ''}
           </div>
           
           <div className="form-group">
           <label htmlFor="buletin">Buletin ALB</label>
           <input type="file" className="form-control" id="bluetin" name="bluetin" onChange={e => setBuletin(e.target.files[0])}  />
           </div>
           <div className="form-group">
           <label htmlFor="buletinEn">Newsletter ENG</label>
           <input type="file" className="form-control" id="bluetinEn" name="bluetinEn" onChange={e => setBuletinEn(e.target.files[0])}   />
           </div>
           <div className="card-footer d-flex justify-content-center mb-3">
           <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
           </div>
           </div>
        
       </div>
       </form>
   </div>
</div>
</div>
    )
}


export default AddBuletin;