import React ,{ useState, useEffect} from 'react';
import axios from 'axios';
import './Project.css';
import { Link } from 'react-router-dom';


const GetProject = () => {
    const [value, setValue] = useState([])
    const [sizePage, setSizePage] = useState(5)
    useEffect(() => {
        axios.get(`/api/project?size=${sizePage}`)
            .then(res => setValue(res.data.project))
            .catch(err => console.log(err))
    }, [sizePage])

    const deleteProjectById = (id) => {
        axios.delete(`/api/project/delete/${id}`)
            .then(res => {
                window.location.reload()
            })
            .catch(err => console.log(err))
    }

    const loadMoreProject = () => {
        setSizePage(sizePage + 5)
    }
    return(
        <div>

        <Link className="btn btn-sm btn-link mt-3  board-button" to="/addProject" >Add Project</Link>
        <hr className="mt-3"/>
        <button onClick={loadMoreProject} className="btn btn-md btn-info load  mt-3 mb-3">Load more projects</button>
    <div className="container">
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col">Project</th>
                    <th scope="col">Link Name</th>
                    <th scope="col">URL</th>
                    <th scope="col">View Photo</th>
                    <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                
                {value.map(data => (
                <tr key={data._id}>    
                <td>{data.name}</td>
                <td>{data.name_link}</td>
                <td>{data.web_link}</td>
                
                
                <td><a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api//${data.image}`}>View Photo</a></td>
                <td>
                    <Link className="btn btn-sm btn-info" to={`editProject/${data._id}`}>Edit</Link>
                    <button className="btn btn-sm btn-danger" type="button" onClick={() => deleteProjectById(data._id)}>Delete</button>
                </td>
                </tr>
                
                ))} 
                    
                </tbody>
            </table>
            

            </div>
       
    </div>
    )
}

export default GetProject;