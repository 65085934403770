import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Mision.css"

const Mision = () => {
    const [mision, setMision] = useState({})
    const lang = localStorage.getItem('lang') || 'al'
    useEffect(() => {
        axios.get("/api/mision")
            .then(res => {
                setMision(res.data[0])
            })
            .catch(err => console.log(err))
    },[])

    const path = `https://www.csrkosovo.org/api/${mision.image}`
    const pathimage =  path.replace(/\\/g, "/");
    return(
        <div 
        className="img-fluid mision__body"
        style={{
            width: "100%",
            height: "800px",
            backgroundImage: `url(${`${pathimage}`})`
          }}
        >
           <div className="row d-flex justify-content-center" >
               <div className="col-md-6 col-sm-12"> 
                    {lang === 'al' ? (
                        <div>
                        <h1 className="title text-center">{mision.title}</h1>
                        <div className="desc mt-5 text-center" dangerouslySetInnerHTML={{__html: mision.description}}>
                           
                        </div>
                        </div>
                    ): (
                        <div>
                        <h1 className="title text-center">{mision.title_en}</h1>
                        <div className="desc mt-5 text-center" dangerouslySetInnerHTML={{__html: mision.description_en}}>
                            
                        </div>
                        </div>
                    )}
                   
                    
                </div>
           </div>
        </div>
    )
}

export default Mision