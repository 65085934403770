import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ShowItemsNews from './newItems/ShowItemsNews';
import { useParams } from 'react-router-dom';
// import{ FacebookShareButton , FacebookIcon} from 'react-share';
import {
    FacebookShareButton,
    FacebookIcon,
  } from 'next-share';
  
import './News.css'
const ShowNews = () => {
    const {id} = useParams()
    const lang = localStorage.getItem('lang') || 'al';
    const [news, setNews] = useState({})
    const [value, setValue] = useState([])

    useEffect(() => {
        axios.get(`/api/news/show/${id}`)
            .then(res =>{ 
                setNews(res.data)
            })
            .catch(err => console.log(err))
    }, [id])

    useEffect(() => {
            axios.get("/api/news")
                .then(res => setValue(res.data.news))
                .catch(err => console.log(err))
    }, [])
    return(
        <div className="container">
        <div className="row">
            <div className="col-md-8 col-sm-12">
                {lang === 'al' ? (
                    <div>
                        <h1 className="mt-5">{news.name}</h1>
                        <p className="data__news">{news.data_news}</p>
                        <img src={`https://www.csrkosovo.org/api/${news.image}`} className="img-fluid image_news_item mt-3" alt="..."/>
                        <hr />
                        <div dangerouslySetInnerHTML={{__html: news.description}}></div>
                        <FacebookShareButton className="mt-5 mb-3"  windowWidth="800"
                            // windowHeight="500"
                            // appId={233513998360969}
                            url={`https://www.csrkosovo.org/showNews/${news._id}`}  
                            imageURL ={`https://www.csrkosovo.org/api/${news.image}`}
                            pictureUrl ={`https://www.csrkosovo.org/api/${news.image}`}
                            // url={`https://www.csrkosovo.org/api/${news.image}`}  
                            // image={
                            //     `https://www.csrkosovo.org/api/${news.image}`
                            //   }
                            //   imageURL={
                            //     `https://www.csrkosovo.org/api/${news._id}`
                            //   }  
                            quote={news.name}
                           >
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                    </div>
                ) : (
                    <div>
                    <h1 className="mt-5">{news.name_en}</h1>
                    <img src={`https://www.csrkosovo.org/api/${news.image}`} className=" img-fluid image_news_item mt-3" alt="..."/>
                    <hr />
                    <div dangerouslySetInnerHTML={{__html: news.description_en}}></div>
                    <FacebookShareButton 
                    url={`https://csrkosovo.org/showNews/${news._id}`}   
                    quote={value.name_en}
                    image={
                        `https://www.csrkosovo.org/api/${news.image}`
                      }
                        
                    >
                            <FacebookIcon className="mt-5 mb-3" size={40} round={true} />
                        </FacebookShareButton>
                </div>  
                )}
               
            </div>
            <div className="col-md-4 col-sm-12 mt-5">
                    {lang === 'al' ? (<p className="title_news_items">LAJME TË TJERA</p>) : (<p className="title_news_items">OTHER NEWS </p>)}
            
            
               {value.slice(0, 3).map(data => (
                 <ShowItemsNews data={data} lang={lang} key={data._id}/>  
               ))}
            </div>
        </div>
        </div>
    )
}

export default ShowNews