import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


const  GetBoard = () => {
    const [values, setValues] = useState([])
    useEffect(() => {
        axios.get("/api/board")
        .then(res => {
            setValues(res.data)
        })
        .catch(err => console.log(err))
    }
    ,[])

    const deleteBoardById = (id)=>{
        axios.delete(`/api/board/delete/${id}`)
                .then(res => {
                    window.location.reload()
                })
                .catch(err => console.log(err))
    }
    return (
        <div>
            <Link className="btn btn-sm btn-link mt-3  board-button" to="/addBoard" >Add New Board Member</Link>
            <hr className="mt-3"/>

        <div className="container">
                <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Surname </th>
                        <th scope="col">Position at CSR ALB</th>
                        <th scope="col">Job Position ALB </th>
                        <th scope="col">Position at CSR ENG</th>
                        <th scope="col">Job Position ENG</th>
                        <th scope="col">View Photo</th>
                        <th scope="col">Action</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                    
                    {values.map(data => (
                    <tr key={data._id}>    
                    <td>{data.name}</td>
                    <td>{data.surname}</td>
                    <td>{data.position_on_csr}</td>
                    <td>{data.position_job}</td>
                    <td>{data.position_on_csr_en}</td>
                    <td>{data.position_job_en}</td>
                    <td><a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api/${data.image}`}>View Photo</a></td>
                    <td>
                        <Link className="btn btn-sm btn-info" to={`/editBoard/${data._id}`}>Edit</Link>
                        <button className="btn btn-sm btn-danger" type="button" onClick={() => deleteBoardById(data._id)}>Delete</button>
                    </td>
                    </tr>
                    
                    ))} 
                        
                    </tbody>
                </table>
                

                </div>
           
        </div>
    )
}

export default GetBoard;