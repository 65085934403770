import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import "./News.css";

const News = ({ match }) => {
   
    const [result, setResult] = useState([])
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);

    const [pageNumberLimit, setPageNumberLimit] = useState(5)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

    const lang = localStorage.getItem('lang') || 'al'

    const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
  
    useEffect(() => {
        axios.get(`/api/news?page=${pageNumber}`)
            .then(result => {
                setResult(result.data.news)
                setNumberOfPages(result.data.totalPages)
            })
            .catch(err => console.log(err))
    }, [pageNumber])

   
    const gotoPrevious = () => {
        setPageNumber(Math.max(0, pageNumber - 1));

        if((pageNumber - 1) % pageNumberLimit == 0){
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
        }
    };

    const gotoNext = () => {
        setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));

        if(pageNumber + 1 > maxPageNumberLimit){
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
        }
    };
    let pageIncrementButton = null;
    if(pages.length > maxPageNumberLimit){
        pageIncrementButton = <li className="page-item" > <button className="page-link" onClick={gotoNext}>&hellip;</button></li>
    }
    let pageDecrementButton = null;
    if(pages.length > maxPageNumberLimit){
        pageDecrementButton = <li className="page-item" ><button className="page-link" onClick={gotoPrevious}> &hellip;</button></li>
    }


    const renderPagesNumber = pages.map((number) => {
       
        if(number <= maxPageNumberLimit   && number >= minPageNumberLimit){
          
        return(

            <li key={number}
            id={number}
            onClick={() => setPageNumber(number)}
            className={number === pageNumber ? "page-item active" : "page-item"}
            ><button className="page-link">{number + 1}</button></li>
        )
        }else{
            return null;
        }
    })

    return(
        <div className="row ml-5 mr-5" >
            
            {result.map(data => (
               
                <div className="col-md-6 col-sm-12 col-lg-4 mt-3 " key={data._id}>
                <div >
                    <img className="img-fluid img_news" src={`https://www.csrkosovo.org/api/${data.image}`} alt="..." />
                    {lang === 'al' ? (
                        <div>
                        <p className="data__news">{data.data_news}</p>
                        <h4 className="h4__news">{data.name}</h4>
                        <p>
                        <Link className="button__news mt-3" to={`/showNews/${data._id}`}>LEXO ME SHUMË</Link>
                        </p>
                        </div>
                    ): (
                        <div>
                        <p className="data__news">{data.data_news}</p>
                        <h4 className="h4__news">{data.name_en}</h4>
                        <p>
                        <Link className="button__news mt-3" to={`/showNews/${data._id}`}>READ MORE</Link>
                        </p>
                        </div>
                    )}
                 
                </div>
            </div>
            
            
            ))}
           
            
        <div className="d-flex justify-content-center mt-3">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li className="page-item"><button className="page-link" onClick={gotoPrevious}>Previous</button></li>
                    
                    {pageDecrementButton}
                    {renderPagesNumber}
                    {pageIncrementButton}
                    <li className="page-item"> <button  className="page-link" onClick={gotoNext}>Next</button></li>
                </ul>
            </nav>
        </div>
            
        </div>
    )
}

export default News