import React from 'react';
import csrlogowhite from '../../../img/csrlogowhite.png';
import './Menu.css'
import  { Link }  from 'react-router-dom';

const Menu = () => {
    return(
        <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          {/* Brand Logo */}
          <a href="/user" className="brand-link">
            <img src={csrlogowhite} width="200" className="menu img-fluid"/>
          </a>
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar bg-dark flex-column" data-widget="treeview" role="menu" data-accordion="false">
                {/* Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library */}
              
                <li className="nav-item has-treeview ">
                  <Link to="/user" className="nav-link ">
                    <i class="fas fa-registered"></i>
                    <p className="ml-3">
                       Admin
                      
                    </p>
                  </Link>
                </li>

                <li className="nav-item has-treeview ">
                  <Link to="/getConnection" className="nav-link ">
                  <i className="far fa-address-card" />
                    <p className="ml-3">
                       About
                    </p>
                  </Link>
                </li>

                <li className="nav-item has-treeview ">
                  <Link to="/getMision" className="nav-link ">
                    <i className="fas fa-low-vision" />
                    <p className="ml-3">
                       Mission and Vision
                    </p>
                  </Link>
                </li>

                <li className="nav-item has-treeview ">
                  <Link to="/getBoard" className="nav-link ">
                    <i className="fas fa-chess-board" />
                    <p className="ml-3">
                       Board
                      
                    </p>
                  </Link>
                </li>

                <li className="nav-item has-treeview ">
                  <Link to="/getManagment" className="nav-link ">
                    <i className="fas fa-tasks" />
                    <p className="ml-3">
                       Management
                    </p>
                  </Link>
                </li>

                <li className="nav-item has-treeview ">
                  <Link to="/addPdf" className="nav-link ">
                    <i class="far fa-file-pdf"></i>
                    <p className="ml-3">
                       Statute and Strategy
                    </p>
                  </Link>
                </li>

                <li className="nav-item has-treeview ">
                  <Link to="/getProject" className="nav-link ">
                    <i className="fas fa-project-diagram" />
                    <p className="ml-3">
                       Projects
                    </p>
                  </Link>
                </li>

                <li className="nav-item has-treeview ">
                  <Link to="/getMembers" className="nav-link ">
                    <i className="fas fa-user-friends" />
                    <p className="ml-3">
                       Members
                    </p>
                  </Link>
                </li>

                <li className="nav-item has-treeview ">
                  <Link to="/getNew" className="nav-link ">
                  <i class="fas fa-newspaper"></i>
                    <p className="ml-3">
                       News
                    </p>
                  </Link>
                </li>
                
                <li className="nav-item has-treeview ">
                  <Link to="/getBuletin" className="nav-link ">
                    <i className="fas fa-paper-plane" />
                    <p className="ml-3">
                       Newsletter
                    </p>
                  </Link>
                </li>

                <li className="nav-item has-treeview ">
                  <Link to="/GetBanner" className="nav-link ">
                  <i className="fas fa-photo-video" />

                    <p className="ml-3">
                       Slider
                    </p>
                  </Link>
                </li>
              
                <li className="nav-item has-treeview ">
                  <Link to="/getFooter" className="nav-link ">
                    <i className="fas fa-shoe-prints" />
                    <p className="ml-3">
                       Footer
                    </p>
                  </Link>
                </li>
              
               
                <li className="nav-item has-treeview ">
                  <Link to="/GetFiles" className="nav-link ">
                    <i className="fas fa-file-word" />
                    <p className="ml-3">
                      Add Files
                    </p>
                  </Link>
                </li>
               
                
                
               
              </ul>
            </nav>
            
          </div>
          
        </aside>
      </div>
    )
}

export default Menu;