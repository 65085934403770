import React from 'react';
import './ShowItemsNews.css'
const ShowItemsNews = ({ data, lang }) => {
    return(
        <div className="row">
            <div className="col-12" key={data._id}>
                {lang === 'al' ? (
                    <div>
                    
                    <img src={`https://www.csrkosovo.org/api/${data.image}`} className="img-items img-fluid" alt="..."/>
                    <p className="datanews">{data.data}</p>
                    <h4 className="namenews">{data.name}</h4>
                    <a href={`/showNews/${data._id}`} className="button_items">LEXO ME SHUMË</a>
                    </div>
                ): (
                    <div>
                    
                    <img src={`https://www.csrkosovo.org/api/${data.image}`} className="img-fluid img-items" alt="..."/>
                    <p className="datanews">{data.data}</p>
                    <h4 className="namenews">{data.name_en}</h4>
                    <a href={`/showNews/${data._id}`} className="button_items">READ MORE</a>
                    </div>
                )}
                
            </div>
            <p  className="hr__items"></p>
        </div>
    )
}


export default ShowItemsNews