import React , { useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './News.css';
const GetNew = () =>{
    const [value, setValue] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(0)

    const [pageNumberLimit, setPageNumberLimit] = useState(5)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)


    const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

    useEffect(() =>{
        axios.get(`/api/news?page=${pageNumber}&size=12`)
            .then(res => {
                setValue(res.data.news)
                setNumberOfPages(res.data.totalPages)
            })
            .catch(err => console.log(err))   

    }, [pageNumber])

      
    const gotoPrevious = () => {
        setPageNumber(Math.max(0, pageNumber - 1));

        if((pageNumber - 1) % pageNumberLimit == 0){
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
        }
    };

    const gotoNext = () => {
        setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));

        if(pageNumber + 1 > maxPageNumberLimit){
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
        }
    };
    let pageIncrementButton = null;
    if(pages.length > maxPageNumberLimit){
        pageIncrementButton = <li className="page-item" > <button className="page-link" onClick={gotoNext}>&hellip;</button></li>
    }
    let pageDecrementButton = null;
    if(pages.length > maxPageNumberLimit){
        pageDecrementButton = <li className="page-item" ><button className="page-link" onClick={gotoPrevious}> &hellip;</button></li>
    }


    const renderPagesNumber = pages.map((number) => {
        if(number <= maxPageNumberLimit    && number >= minPageNumberLimit){
        return(

            <li key={number}
            id={number}
            onClick={() => setPageNumber(number)}
            className={number === pageNumber ? "page-item active" : "page-item"}
            ><button className="page-link">{number}</button></li>
        )
        }else{
            return null;
        }
    })

    const onDeleteNewById = (id) => {
        axios.delete(`/api/news/delete/${id}`)
            .then(res => {
                window.location.reload()
            })
            .catch(err => console.log(err))
    }


    return(
        <div>
        <Link className="btn btn-sm btn-link mt-3  board-button" to="/addNew" >Add News</Link>
        <hr />
         <nav aria-label="Page navigation example">
         <ul class="pagination justify-content-center">
                    <li className="page-item"><button className="page-link" onClick={gotoPrevious}>Previous</button></li>
                    
                    {pageDecrementButton}
                    {renderPagesNumber}
                    {pageIncrementButton}
                    <li className="page-item"> <button  className="page-link" onClick={gotoNext}>Next</button></li>
                </ul>
            </nav>
        <div className="row d-flex justify-content-center ml-3 mr-3">
       
        
        {value.map(data => (
        <div className="col-lg-4 col-md-4 col-sm-12 col-md-6">
        <div className="card" style={{width: "18rem"}}>
        <img src={`https://www.csrkosovo.org/api/${data.image}`}className="img-news-dashboard img-fluid" alt="..."/>
        <div className="card-body">
            <h5 className="card-title mt-3">{data.name}</h5>
         
            <Link className="btn btn-sm btn-link mt-2" to={`/editNew/${data._id}`}>Edit</Link>
            <button className="btn btn-sm btn-link mt-2" onClick={() => onDeleteNewById(data._id)}>Delete</button>
        </div>
        </div>
     
     
        </div>
        ))}
        </div>

           
    </div>
    )
}

export default GetNew