import React, { useState } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddNew = () => {
    const history = useHistory();
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [nameEn, setNameEn] = useState("")
    const [descriptionEn, setDescriptionEn] = useState("")
    const [dataNews, setDataNews] = useState("")
    const [photo, setPhoto] = useState()

    const [error, setError] = useState({
        name: "",
        description: "",
        name_en: "",
        description_en: "",
        data_news: "",
    })

    const onSubmit = (e) => {
      
        e.preventDefault();
        const data = new FormData()
        data.append("name", name)
        data.append("description", description)
        data.append("name_en", nameEn)
        data.append("description_en", descriptionEn)
        data.append("data_news", dataNews)
        data.append("newsImage", photo)


        axios.post("/api/news", data,  { 
            headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
                history.push("/getNew")
            })
            .catch(err => setError(err.response.data))
    }

    return(
        <div>
        <Link className="btn  btn-link board-button mt-3" to="/getNew">Go Back</Link>
        <hr className="mt-3"/>
    
      <div className="row d-flex justify-content-center">
        
        <div className="col-8 mt-5"> 
        <form onSubmit={onSubmit}>
        <div class="card" >
       
        <div class="card-header" style={{background: '#29B473', color:"white"}}>
            <h3 class="text-center">Add News</h3>
         </div>
            <div className="card-body">
            <div className="form-group">
               
            <label htmlFor="name">Name </label>
            <input className="form-control" id="name" name="name" onChange={e => setName(e.target.value)} />
            {error.name ? error.name : ''}
            </div>
           
            <div className="form-group mt-1">
            <label htmlFor="date">Body</label>
            <CKEditor 
                editor={ ClassicEditor }
                 data={description}
               
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    setDescription(data)
                } }
                onBlur={ ( event, editor ) => {
                    console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                    console.log( 'Focus.', editor );
                } 
                }
            />
            </div>

            <div>
            <label htmlFor="nameEn">Name English </label>
            <input className="form-control" id="nameEn" name="nameEn" onChange={e => setNameEn(e.target.value)} />
            {error.name_en ? error.name_en : ''}
            </div>
            
            <div className="form-group mt-2">
            <label htmlFor="descriptionEn">Body English</label>
              <CKEditor 
                editor={ ClassicEditor }
                 data={descriptionEn}
               
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    setDescriptionEn(data)
                } }
                onBlur={ ( event, editor ) => {
                } }
                onFocus={ ( event, editor ) => {
                } 
                }
            />
            </div>

            <div className="form-group">
            <label htmlFor="data_news">Date</label>
            <input className="form-control" id="data_news" name="data_news" onChange={e => setDataNews(e.target.value)}/>
            {error.data_news ? error.data_news : ''}
            </div>
           
            <div className="form-group">
            <label htmlFor="photo">Photo</label>
            <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  placeholder="Enter Positon Job in english" />
            </div>
            <div className="card-footer d-flex justify-content-center mb-3">
            <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
            </div>
            </div>
         
        </div>
        </form>
    </div>
 </div>
 </div>
    )
}


export default AddNew