import React, { useState } from "react";
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
const Register = () => {
    const [name, setName] = useState("");
    const [surname, setSurName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");

   const history = useHistory()


    const [error, setError] = useState({
        name: "",
        surname: "",
        phone: "",
        email: "",
        password: "",
        emailExist: ""
    })

    const onSubmit = (e) =>{
        e.preventDefault();
        const data ={
            name: name,
            surname: surname,
            email: email,
            phone: phone,
            password: password
        }

       
        axios.post("/api/auth/register", data)
            .then(res => 
                {
                  history.push("/User")
                }
            )
            .catch(err => setError(err.response.data))
        
    }

    
    return(
        <div>
        <Link className="btn btn-sm btn-link mt-3  board-button" to="/User" >Go back</Link>
        <hr className="mt-3"/>
          <div className="row d-flex justify-content-center">
            
            <div className="col-8 mt-5"> 
            
            <div class="card card-primary">

            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center">Register</h3>
              </div>
              {error.emailExist ? (<div className="alert alert-danger" role="alert"> {error.emailExist}</div>):null}
              <form  onSubmit={onSubmit}>
              
              
                
                <div className="card-body">
                <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" name="name" onChange={(e) => setName(e.target.value)}placeholder="Enter Name" />
                {error.name ? error.name : null}
                </div>
                <div className="form-group">
                <label htmlFor="surname">SurName</label>
                <input type="text" className="form-control" id="surname" name="surname"  onChange={(e) => setSurName(e.target.value)} placeholder="Enter SurName" />
                {error.surname ? error.surname : null}
                </div>
                <div className="form-group">
                <label htmlFor="emai">Email</label>
                <input type="email" className="form-control" id="email" name="email" onChange={(e) => setEmail(e.target.value)}  placeholder="Enter Email" />
                {error.email ? error.email: null}
                </div>
                <div className="form-group">
                <label htmlFor="Phone">Phone</label>
                <input type="text" className="form-control" id="phone" name="phone" onChange={(e) => setPhone(e.target.value)} placeholder="Enter phone" />
                {error.phone ? error.phone : null}
                </div>
                <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" name="password" onChange={(e) => setPassword(e.target.value)}  placeholder="Enter Password" />
                {error.password ? error.password : null}
                </div>
                </div>

                <div className="card-footer d-flex justify-content-center mb-3">
                  <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
                </div>
              </form>
            </div>
        </div>
    </div>
    </div>
        
    )
}

export default Register;