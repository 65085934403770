import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const GetConnection = () =>{
        const [value, setValue] = useState({
            _id: "",
            title: "",
            description: "",
            title_en: "",
            description_en: "",
            image: ""
        })
    
        useEffect(() => {
            axios.get("/api/conn")
                .then(res => {
                    if(res.data.length !== 0){
                        setValue(res.data[0])
                    }
                })
                .catch(err => console.log(err))
        }, [])
    
        
        return(
            <div>
            <div className="d-flex justify-content-between">
            <div>
            {value ? (<Link className="btn btn-sm btn-link mt-3 board-button" to={`/editConnection/${value._id}`}>Edit About</Link>) : ''}
         
            </div>
            
            </div>
        <hr className="mt-3"/>
        
          <div className="row d-flex justify-content-center">
            
            <div className="col-8 mt-5"> 
            
            <div class="card">
    
            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center">About</h3>
             </div>
                <div className="card-body">
                <div className="form-group">
                   
                <label htmlFor="instagram">Title </label>
                <input className="form-control" id="title" value={value ? value.title : " "}/>
                </div>
                <div className="form-group">
                <label htmlFor="description">Body</label>
                <CKEditor 
                    editor={ ClassicEditor }
                    data={value.description}
                
                
                    onBlur={ ( event, editor ) => {
                    } }
                    onFocus={ ( event, editor ) => {
                    } 
                    }
                />
                 </div>
                <div className="form-group">
                <label htmlFor="title_en">Title English</label>
                <input className="form-control" id="title_en" value={value? value.title_en : " "}/>
                </div>
               


                <div className="form-group">
                <label htmlFor="description_en">Body Englishs</label>
                <CKEditor 
                    editor={ ClassicEditor }
                    data={value.description_en}
                
                
                    onBlur={ ( event, editor ) => {
                    } }
                    onFocus={ ( event, editor ) => {
                    } 
                    }
                />
                 </div>                
                 <div className="form-group">
                 {value ? (<a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api/${value.image}`}>View Photo</a>) : ""}   
                
                </div>
              
                </div>
    
                
              
            </div>
        </div>
    </div>
    </div>
        )
    }
    
export default GetConnection