import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';


const AddMembers = () => {
        const history = useHistory()
        const [name, setName] = useState("")
        const [typeOfMembers, setTypeOfMembers] = useState("")
        const [typeOfMembersEn, setTypeOfMembersEn] = useState("")
        const [url, setUrl] = useState("")
        const [photo, setPhoto] = useState()


        const [error, setError] = useState({
            name: "",
            type_of_members: "",
            type_of_members_en: "",
            url: "",
            image: "" 
        })

        const onSubmit = (e) =>{
            e.preventDefault()
            const data = new FormData();
            data.append("name", name);
            data.append("type_of_members", typeOfMembers);
            data.append("type_of_members_en", typeOfMembersEn);
            data.append("url", url);
            data.append("imageMembers", photo);


            axios.post("/api/members", data, { 
                headers: { "Content-Type": "multipart/form-data" }})
                .then(res => {
                      history.push("/getMembers")
                  })
                .catch(err => setError(err.response.data))
        }

        return(
            <div>
            <Link className="btn  btn-link board-button mt-3" to="/getMembers">Go Back</Link>
            <hr />
          <div className="row d-flex justify-content-center">
            
            <div className="col-8"> 
            
            <div class="card">

            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center">Add  Member</h3>
              </div>

              <form  onSubmit={onSubmit}> 
                
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" name="name" onChange={e => setName(e.target.value)} placeholder="Enter name" />
                    {error.name ? error.name : null}
                  </div>
                  <div className="form-group">
                    <label for="typeOfMembers">Type Of Member Albanian</label>
                    <input type="text" className="form-control" id="typeOfMembers" name="typeOfMembers" onChange={e => setTypeOfMembers(e.target.value)} placeholder="Type of member Albanian" />
                    {error.type_of_members ? error.type_of_members : null}
                  </div>
                  <div className="form-group">
                    <label for="typeOfMembersEn">Type Of Members English</label>
                    <input type="text" className="form-control" id="typeOfMembersEn" name="typeOfMembersEn" onChange={e => setTypeOfMembersEn(e.target.value)} placeholder="Type of member English" />
                    {error.type_of_members_en ? error.type_of_members_en : null}
                  </div>
                  <div className="form-group">
                    <label for="url">URL</label>
                    <input type="text" className="form-control" id="url" name="url" onChange={e => setUrl(e.target.value)} placeholder="URL" />
                    {error.url ? error.url : null}
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="photo">Photo</label>
                    <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  placeholder="Enter Positon Job in english" />
                  </div>



                </div>

                <div className="card-footer d-flex justify-content-center mb-3">
                  <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
                </div>
              </form>
            </div>
        </div>
    </div>
    </div>
        )
}


export default AddMembers;
