import React from 'react';
import "./Members.css"

const Member = ({members, lang}) => {
    return(
        <div className="col-lg-3 col-md-6 col-sm-12 mt-3 mb-3 ">
            
            <a href={members.url} target="_blank">
            <img src={`https://www.csrkosovo.org:/api/${members.image}`} class="card-img-top img-fluid" alt="..." />
            </a>
            <p className="text-center member_name mt-2">{members.name}</p>
            <p className="text-center descript">
            {lang === 'al' ? members.type_of_members : members.type_of_members_en}
            </p>
            
        </div>
    )
}


export default Member