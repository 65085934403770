import React, {useState, useEffect} from "react";
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom'


const EditBoard = () => {
    const {id} = useParams();
    const history = useHistory()
    const [value, setValue] = useState({
        _id: "",
        name: "",
        surname: "",
        position_on_csr: "",
        position_job: "",
        position_on_csr_en: "",
        position_job_en: "",
        image: "",

    }) 
    const [photo, setPhoto] = useState();
    const [error, setError] = useState({
        name: "",
        surname: "",
        position_on_csr: "",
        position_job: "",
        position_on_csr_en: "",
        position_job_en: "",
        image: "",
    })
    
    useEffect(() => {
        axios.get(`/api/board/show/${id}`)
            .then(res => {
                setValue(res.data)
            })
            .catch(err => console.log(err))
      }, [id])

    const handleOnChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    }
    const onSubmit = (e) => {
      
        e.preventDefault();
        const data = new FormData();
        data.append("id", value._id);
        data.append("name", value.name);
        data.append("surname", value.surname);
        data.append("position_on_csr", value.position_on_csr);
        data.append("position_on_csr_en", value.position_job);
        data.append("position_job", value.position_on_csr_en);
        data.append("position_job_en", value.position_job_en);
        data.append("boardImage", photo);
       

        
        axios.post(`/api/board/edit`, data, { 
            headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
                history.push("/getBoard")
            })
            .catch(err => setError(err.response.data))
    }
    return(
        <div>
            <Link className="btn  btn-link board-button mt-3" to="/getBoard">Go Back</Link>
            <hr />
          <div className="row d-flex justify-content-center">
            
            <div className="col-8"> 
            
            <div class="card">

            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center">Edit Board Member</h3>
              </div>
            
              <form  onSubmit={onSubmit}>
                
                <div className="card-body">
                <input type="hidden" className="form-control" id="id" name="id" value={value._id} onChange={handleOnChange} />

                <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" name="name" value={value.name} onChange={handleOnChange} placeholder="Enter Name" />
                {error.name ? error.name : null}
                </div>
                
                <div className="form-group">
                <label htmlFor="surname">Surname</label>
                <input type="text" className="form-control" id="surname" name="surname" value={value.surname} onChange={handleOnChange} placeholder="Enter SurName" />
                {error.surname ? error.surname : null}
                </div>
               
                <div className="form-group">
                <label htmlFor="position_on_csr">Position at CSR ALB</label>
                <input type="text" className="form-control" id="position_on_csr" name="position_on_csr" value={value.position_on_csr} onChange={handleOnChange} placeholder="Enter Position on csr" />
                {error.position_on_csr ? error.position_on_csr : null}
                </div>

                <div className="form-group">
                <label htmlFor="position_job">Job Position ALB </label>
                <input type="text" className="form-control" id="position_job" name="position_job" value={value.position_job} onChange={handleOnChange} placeholder="Enter Position Job" />
                {error.position_on_csr ? error.position_on_csr : null}
                </div>
                  
                <div className="form-group">
                <label htmlFor="position_on_csr_en">Position at CSR ENG</label>
                <input type="text" className="form-control" id="position_on_csr_en" name="position_on_csr_en" value={value.position_on_csr_en} onChange={handleOnChange} placeholder="Enter Position on csr english" />
                {error.position_on_csr_en ? error.position_on_csr_en : null}
                </div>

                <div className="form-group">
                <label htmlFor="position_job_en">Job Position ENG</label>
                <input type="text" className="form-control" id="position_job_en" name="position_job_en" value={value.position_job_en} onChange={handleOnChange} placeholder="Enter position in english" />
                {error.position_on_csr_en ? error.position_on_csr_en : null}
                </div>

                <div className="form-group">
                    <label htmlFor="photo">Photo</label>
                    <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  placeholder="Enter Positon Job in english" />
                    <a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api/${value.image}`}>View Photo</a>
                </div>


                </div>

                <div className="card-footer d-flex justify-content-center mb-3">
                  <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
                </div>
              </form>
            </div>
        </div>
    </div>
    </div>
    )
}

export default EditBoard