import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Connection.css"
const Connection = () => {
    const [conn, setConn] = useState({})
    const lang = localStorage.getItem('lang') || 'al'
    const [photo, setPhoto] = useState("")
    useEffect(() => {
        axios.get("/api/conn")
            .then(res => 
                {
                    setPhoto(res.data[0].image)
                    setConn(res.data[0])
                })
            .catch(err => console.log(err))
    },[])

  
    const pathimage =  photo.replace(/\\/g, "/");
    return(
        <div 
        className="img-fluid mision__body"
        style={{
            width: "100%",
            height: "1200px",
            backgroundImage: `url(${`https://www.csrkosovo.org/api/${pathimage}`})`,
            // backgroundRepeat: "no-repeat"
          }}
        >
           <div className="row d-flex justify-content-center" >
               <div className="col-md-6 col-sm-12"> 
                    {lang === 'al' ? (
                        <div>
                        <h1 className="title text-center">{conn.title}</h1>
                        <div className="desc mt-5">
                        <div className="text-center" dangerouslySetInnerHTML={{__html: conn.description}}></div>
                        </div>
                        </div>
                    ): (
                        <div>
                        <h1 className="title text-center">{conn.title_en}</h1>
                        <div className="desc mt-5">
                        <div className="text-center" dangerouslySetInnerHTML={{__html: conn.description_en}}></div>
                        </div>
                        </div>
                    )}
                   
                    </div>
                    
                </div>
           </div>
    )
}

export default Connection