import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


const GetFiles = () => {
    const [values, setValues] = useState([]);


    useEffect(()=>{
        axios.get("/api/files")
            .then(res => {
                setValues(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    const onDeleteFilesById = (id) => {
        axios.delete(`/api/files/delete/${id}`)
                .then(res => {
                    window.location.reload()
                })  
                .catch(err => console.log(err))
    }
    return(
        <div>
            <Link className="btn btn-sm btn-link mt-3  board-button" to="/AddFiles" >Add Files</Link>
            <hr />
          
            {values.map(data => (         
            <div key={data._id}>
            <div className="row mt-2" >
            <div className="col-md-3 col-sm-12 col-lg-3 d-flex justify-content-center">
                <h6>{data.name}</h6>
            </div>
            <div className="col-md-3 col-sm-12 col-lg-3 d-flex justify-content-center">
                <p className='mr-5'>{`https://www.csrkosovo.org/api/${data.files}`}</p>
            </div>
            <div className="col-md-3 col-sm-12 col-lg-3 d-flex justify-content-center ">
               <p className='ml-2'>{`https://www.csrkosovo.org/api/${data.files_en}`}</p> 
            </div>
            <div className="col-md-3 col-sm-12 col-lg-2 d-flex justify-content-center ">
                    <button className="btn btn-sm btn-danger" onClick={() => onDeleteFilesById(data._id)}>Delete</button>
            </div>
            </div>
            </div>
            ))}
          
            
        </div>
    )
}

export default GetFiles