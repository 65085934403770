import  React, { useState } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

const AddProject = () => {
        const history = useHistory();
        const [name, setName] = useState("")
        const [description, setDescription] = useState("")
        const [descriptionEn, setDescriptionEn] = useState("")
        const [nameLink, setNameLink] = useState("")
        const [webLink, setWebLink] = useState("")
        const [photo, setPhoto] = useState()

        

        const [error, setError] = useState({
            name:"",
            description: "",
            description_en: "",
            name_link: "",
            web_link: ""
        })

        const onSubmit = (e) => {
            e.preventDefault()
            const data = new FormData()
            data.append('name', name)
            data.append('description', description)
            data.append('description_en', descriptionEn)
            data.append('name_link', nameLink)
            data.append('web_link', webLink)
            data.append('imageProject', photo)

            axios.post("/api/project", data, { 
                headers: { "Content-Type": "multipart/form-data" }})
                    .then(res => {
                        history.push("/getProject")
                    })
                    .catch(err => setError(err.response.data))
        }
        return(
            <div>
            <Link className="btn  btn-link board-button mt-3" to="/getProject">Go Back</Link>
            <hr />
          <div className="row d-flex justify-content-center">
            
            <div className="col-8"> 
            
            <div class="card">

            <div class="card-header" style={{background: '#29B473', color:"white"}}>
                <h3 class="text-center">Add  Project</h3>
              </div>
            
              <form onSubmit={onSubmit}>
              
              
                
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" name="name" onChange={e => setName(e.target.value)} placeholder="Enter name" />
                    {error.name ? error.name : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description ALB</label>
                    <textarea type="text" className="form-control" id="description" name="description" onChange={e => setDescription(e.target.value)} placeholder="Descripton ALB" ></textarea>
                    {error.description ? error.description : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="description_en">Description ENG</label>
                    <textarea type="text" className="form-control" id="description_en" name="description_en" onChange={e => setDescriptionEn(e.target.value)} placeholder="Descripton ENG" ></textarea>
                    {error.description_en ? error.description_en : null}
                  </div>
                  <div className="form-group">
                    <label for="nameLink">URL Name</label>
                    <input type="text" className="form-control" id="nameLink" name="nameLink" onChange={e => setNameLink(e.target.value)} placeholder="URL Name" />
                    {error.name_link ? error.name_link : null}
                  </div>
                  <div className="form-group">
                    <label for="webLink">URL</label>
                    <input type="text" className="form-control" id="webLink" name="webLink" onChange={e => setWebLink(e.target.value)} placeholder="URL" />
                    {error.web_link ? error.web_link : null}
                  </div>
                 
                  
                  <div className="form-group">
                    <label htmlFor="photo">Photo</label>
                    <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  placeholder="Enter Positon Job in english" />
                  </div>



                </div>

                <div className="card-footer d-flex justify-content-center mb-3">
                  <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
                </div>
              </form>
            </div>
        </div>
    </div>
    </div>
        )
}

export default AddProject;
