import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';



const EditBuletin = () => {
    const { id } = useParams()
    const history = useHistory()
    const [value, setValue] = useState({
        _id: "",
        name: "",
        name_english: "",
        file: ""
    })
    const [buletin, setBuletin] = useState()
    const [buletinEn, setBuletinEn] = useState()

    const [error, setError] = useState({
        name: "",
        description: "",
        date: ""
    })

    const handleOnChnage = (e) =>{
        setValue({ ...value, [e.target.name]: e.target.value });
    }
    useEffect( () => {
                axios.get(`/api/buletin/show/${id}`)
                        .then(res => setValue(res.data))
                        .catch(err => console.log(err))
    },[id])
    const onSubmit = (e) => {
        e.preventDefault()

        const data = new FormData()
        data.append("id", value._id)
        data.append("name", value.name)
        data.append("name_english", value.name_english)
        data.append("buletin", buletin)
        data.append("buletin_en", buletinEn)


        axios.post("/api/buletin/edit", data, { 
            headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
                history.push("/getBuletin")
            })
            .catch(err => setError(err.response.data))
    }
    return(
        <div>
        <Link className="btn  btn-link board-button mt-3" to="/getBuletin">Go Back</Link>
        <hr className="mt-3"/>
    
      <div className="row d-flex justify-content-center">
        
        <div className="col-8 mt-5"> 
        <form onSubmit={onSubmit}>
        <div class="card card-primary">
       
        <div class="card-header" style={{background: '#29B473', color:"white"}}>
            <h3 class="text-center">Edit Buletin</h3>
         </div>
            <div className="card-body">
            <div className="form-group">

            <input name="id" type="hidden" value={value._id} onChange={handleOnChnage}/>  
            <label htmlFor="name">Name </label>
            <input className="form-control" id="name" name="name" value={value.name}onChange={handleOnChnage} />
            {error.name ? error.name : ''}
            </div>
            <div className="form-group">
            <label htmlFor="name_english">Name English</label>
            <input  className="form-control" id="name_english" name="name_english" value={value.name_english} onChange={handleOnChnage}/>
            {error.name_english ? error.name_english : ''}
            </div>
       
            <div className="form-group">
            <label htmlFor="buletin">Buletin</label>
            <input type="file" className="form-control" id="buletin" name="buletin" onChange={e => setBuletin(e.target.files[0])}  placeholder="Enter Positon Job in english" />
            <a className="btn btn-sm btn-link"href={`https://www.csrkosovo.org/api/s${value.buletin}`}>View Buletin</a>
            </div>
            
            <div className="form-group">
            <label htmlFor="buletinEn">Buletin</label>
            <input type="file" className="form-control" id="buletinEn" name="buletinEn" onChange={e => setBuletinEn(e.target.files[0])}  placeholder="Enter Positon Job in english" />
            <a className="btn btn-sm btn-link"href={`https://www.csrkosovo.org/api/${value.buletin_en}`}>View Buletin English</a>
            </div>
            <div className="card-footer d-flex justify-content-center mb-3">
            <button type="submit" className="btn " style={{background: '#29B473', color:"white"}}>Submit</button>
            </div>
            </div>
         
        </div>
        </form>
    </div>
 </div>
 </div>
    )
}

export default EditBuletin
