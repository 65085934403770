import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ManagmentImage from './managmentImage/ManagmentImage';
import BoardImage from './boardImage/BoardImage';
import './Board.css'

const Board = () => {  
    const lang = localStorage.getItem('lang')
    const [board, setBoard] = useState([])
    const [managment, setMangment] = useState([])
    

    useEffect(() => {
        axios.get("/api/board")
            .then(res =>{ 
                setBoard(res.data)
            })
            .catch(err => console.log(err))
    }
    ,[])

    useEffect(() => {
        axios.get("/api/managment")
            .then(res => setMangment(res.data))
            .catch(err => console.log(err))
    },[])
    return(
        <div>
            <div className="row  d-flex justify-content-center ">
                <div className="col-lg-6 col-md-6 col-sm-12">
                {lang === 'al' ? (
                    <div>
                     <h1 className="h1_board text-center ">BORDI  I DREJTORËVE</h1>
                     <p className="text__board text-center">Bordi i Drejtorëve është organ drejtues i caktuar nga kuvendi
                      i Rrjetit CSR Kosova i cili i ka kaluar te drejtat per vendime rreth funksionalizimit te zyres. 
                      Detyra kryesore e kësaj strukture ështe zhvillimi i strategjisë së organizatës, si dhe monitorimi mbi 
                      zbatimin e saj nga menaxhmenti i autorizuar. Secili anëtar i bordit ka drejtën e mendimit, 
                      vërejtjes ose sugjerimit, mirëpo të gjitha vendimet e bordit merren nëpërmjet votimeve.
                      </p>
                    </div>
                ) : (
                    <div>
                    <h1 className="h1_board text-center ">BOARD OF DIRECTORS</h1>
                    <p className="text__board text-center">
                    The Board of Directors is a governing body appointed by the assembly
                    of CSR Kosovo Network which has passed the rights for decisions about the functioning of the office.
                    The main task of this structure is to develop the strategy of the organization, as well as monitoring over
                    its implementation by authorized management. Each board member has the right to an opinion,
                    remark or suggestion, but all board decisions are made through voting. 
                     </p>
                   </div>
                )}
               
                </div>
            </div>
            
          

            <div className="row mt-5">
            
            {board.map(board => (
               <BoardImage  board={board} lang={lang} key={board._id}/>
                
            ))}
            </div>


            
            <div className="row  d-flex justify-content-center ">
                <div className="col-lg-6 col-md-6 col-sm-12">
                {lang === 'al' ? (
                    <div>
                     <h1 className="h1_managmnet text-center ">MENAXHMENTI</h1>
                     <p className="text__mangament text-center">
                     Ekipi i menaxhmentit është pjesë thelbësore e organizatës sonë.
                      Ata punojnë kontinualisht duke kontribuar drejt Zhvillimit të Qëndrueshëm, duke promovuar koncepte, 
                      ide dhe duke ndërmarrë veprime që rrisin ndikimin e përgjegjësisë shoqërore të korporatave. 
                     Ne jemi të përkushtuar për të rritur rëndësinë e CSR duke adresuar sfidat shumë-dimensionale me të cilat përballet shoqëria jonë.
                      </p>
                    </div>
                ) : (
                    <div>
                    <h1 className="h1_managmnet text-center ">MANAGEMENT</h1>
                    <p className="text__mangament text-center">
                    The management team is a crucial part of our organisation. They work daily in contributing towards Sustainable Development, by promoting concepts, ideas and taking actions that enhance the impact of corporate social responsibility. We are committed to increasing the importance of CSR by addressing many-dimensional challenges our society faces. 
                     </p>
                   </div>
                )}
               
                </div>
            </div>

            <div className="row mt-5">
 
                {managment.map(managment => (
                    <ManagmentImage managment={managment} lang={lang} key={managment._id} />
                ))}
            </div>
        </div>
    )
}

export default Board