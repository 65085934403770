import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const EditMembers = () => {
    const {id} = useParams();
    const history = useHistory();
    const [value, setValue] = useState({
        _id: "",
        name: "",
        type_of_members: "",
        type_of_members_en: "",
        url: ""
    })
    const [photo, setPhoto] = useState();

    const [error, setError] = useState({
        name:"",
        type_of_members:"",
        type_of_members_en: "",
        url:""
    })
    useEffect( () => {
        axios.get(`/api/members/show/${id}`)
            .then(res => {
                setValue(res.data)
            })
            .catch(err => {console.log(err)})
    }, [id])

    const handleOnChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    }

    const onSubmit = (e) =>{
        e.preventDefault()
        const data = new FormData();
        data.append("id", value._id)
        data.append("name", value.name);
        data.append("type_of_members", value.type_of_members);
        data.append("type_of_members_en", value.type_of_members_en);
        data.append("url", value.url);
        data.append("imageMembers", photo);

        axios.post("/api/members/edit", data, { 
            headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
                history.push("/getMembers")
            })
            .catch(err => {
                 setError(err.response.data)
            })
    }

    return(
        <div>
        <Link className="btn  btn-link board-button mt-3" to="/getMembers">Go Back</Link>
        <hr />
      <div className="row d-flex justify-content-center">
        
        <div className="col-8"> 
        
        <div class="card">

        <div class="card-header" style={{background: '#29B473', color:"white"}}>
            <h3 class="text-center">Edit Members</h3>
          </div>
          <form  onSubmit={onSubmit}>
            
            <div className="card-body">
            <input type="hidden" className="form-control" id="id" name="id" value={value._id} onChange={handleOnChange} />

            <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" className="form-control" id="name" name="name" value={value.name} onChange={handleOnChange} placeholder="Enter Name" />
            {error.name ? error.name : null}
            </div>
            
            <div className="form-group">
            <label htmlFor="type_of_members">Type of members</label>
            <input type="text" className="form-control" id="type_of_members" name="type_of_members" value={value.type_of_members} onChange={handleOnChange} placeholder="Enter Type of member" />
            {error.type_of_members ? error.type_of_members : null}
            </div>
            <div className="form-group">
            <label htmlFor="type_of_members_en">Type of members</label>
            <input type="text" className="form-control" id="type_of_members_en" name="type_of_members_en" value={value.type_of_members_en} onChange={handleOnChange} placeholder="Enter Type of member" />
            {error.type_of_members_en ? error.type_of_members_en : null}
            </div>
            <div className="form-group">
            <label htmlFor="url">Url</label>
            <input type="text" className="form-control" id="url" name="url" value={value.url} onChange={handleOnChange} placeholder="Enter Type of member" />
            {error.url ? error.surname : null}
            </div>
        
            <div className="form-group">
                <label htmlFor="photo">Photo</label>
                <input type="file" className="form-control" id="photo" name="photo" onChange={e => setPhoto(e.target.files[0])}  placeholder="Enter Positon Job in english" />
                <a className="btn btn-sm btn-link" href={`https://www.csrkosovo.org/api/${value.image}`}>View Photo</a>
            </div>


            </div>

            <div className="card-footer d-flex justify-content-center mb-3">
              <button type="submit" className="btn" style={{background: '#29B473', color:"white"}}>Submit</button>
            </div>
          </form>
        </div>
    </div>
</div>
</div>
    )
}

export default EditMembers;