import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Member from './member/Member';
const Members = () => {
    const [members, setMembers] = useState([])
    const lang = localStorage.getItem("lang") || 'al'
    useEffect(() => {
        axios.get("/api/members")
            .then(res => setMembers(res.data))
            .catch(err => console.log(err))
    },
    [])
    return(
        <div className="container">
            <div className="row">
            {members.map(members => (
                <Member members={members} lang={lang} key={members._id}/>
            ))}
            </div>
        </div>
    )
}

export default Members