import React, { useState, useEffect} from 'react';
import axios from 'axios';
import "./buletin.css"
import photo from '../../img/photo.png';
const Buletin = () => {
    const lang = localStorage.getItem("lang") || 'al';
    const [value, setValue] = useState([])
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);

    const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

    useEffect( () => {
        axios.get(`/api/buletin?page=${pageNumber}`)
                .then(res => {
                    setValue(res.data.buletin)
                    setNumberOfPages(res.data.totalPages)
                })
                .catch(err => console.log(err))
    }, [pageNumber])
    
    const gotoPrevious = () => {
        setPageNumber(Math.max(0, pageNumber - 1));
    };

    const gotoNext = () => {
        setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
    };
    return(
        <div>
            <div className="row d-flex justify-content-center">
                <div className="img-fluid"
                
                 style={{
                     width: "100%",
                     height: "250px",
                     backgroundImage: `url(${photo}) ` ,
                     backgroundPosition: 'center',
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat'
                     
                     
                   }}
                
                
                >
                <h1 className="text-center mt-5" style={{color: 'white'}}>{lang === 'al' ? 'Buletinët e Rrjetit CSR Kosova' : 'Kosovo CSR Network Newsletters'}</h1>
                <p className="text-center mt-5" style={{color: 'white'}}>
                    {lang === 'al' ? 'Për tu njoftuar me aktivitetet dhe lajmet e fundit të  Rrjetit CSR Kosova, klikoni në  buletinat e më  poshtëm.' : 'To be up to date with the news and events of Kosovo CSR Network, click on the newsletters below.'}
                   
                </p>
                </div>
            </div>
            <div className="row d-flex- justify-content-center mt-5">
                <div className="col-lg-6 col-sm-12 col-md-6 mb-5">
               
                <ul class="list-group list-group-flush ">
                   {lang === 'al' ? (
                       value.map(data => (
                        <li class="list-group-item" key={data._id}><span style={{color: '#16a78f'}}>{data.name}</span><a className="button_buletin" href={`https://csrkosovo.org/api/${data.buletin}`}>SHKARKO</a></li>
                       ))
                   ): (
                       value.map(data => (
                        <li class="list-group-item" key={data._id}><span style={{color: '#16a78f'}}>{data.name_english}</span><a className="button_buletin" href={`https://csrkosovo.org/api/${data.buletin_en}`}>DOWNLOAD</a></li>
                       ))
                   )}
                    
                    
                </ul>
                <div className="d-flex justify-content-center mt-3">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li className="page-item"><button className="page-link" onClick={gotoPrevious}>Previous</button></li>
                            {pages.map((pageIndex) => (
                            <li className="page-item" ><button  className="page-link" key={pageIndex} onClick={() => setPageNumber(pageIndex)}>
                            {pageIndex + 1}
                            </button></li>
                            ))}
                            <li className="page-item"> <button  className="page-link" onClick={gotoNext}>Next</button></li>
                        </ul>
                    </nav>
                </div>
                </div>
            </div>
            
            
        </div>
    )
}

export default Buletin