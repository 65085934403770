import React from 'react';
import './ManagmentImage.css'


const ManagmentImage = ({managment, lang}) => {
    return (
        <div className="col-md-6 col-lg-4  col-sm-12 mt-3">
            {lang === 'al' ? (
              <div className="text-center">
              <img className="rounded-circle text-center" src={`https://www.csrkosovo.org/api/${managment.image}`} alt="..." width="300"/>
              <p className="text-center bname1">{managment.name} {managment.surname}</p>
              <p className="bbottom mt-3"></p>
         
              <p className="bprof1 mt-3">{managment.position_on_csr}</p>
              
              </div>
            ) : (
            <div className="text-center">
            <img className="rounded-circle text-center" src={`https://www.csrkosovo.org/api/${managment.image}`} alt="..." width="300"/>
            <p className="text-center bname1">{managment.name} {managment.surname}</p>
            <p className="bbottom mt-3"></p>
            <p className="bprof1 mt-3">{managment.position_on_csr_en}</p>
           
            </div>
            ) }
            
    
        </div>

    )
}


export default ManagmentImage