import React from 'react';
import './BoardImage.css'

const BoardImage =({board, lang}) => {
    return (
        <div className="col-lg-4 col-md-6 col-sm-12 mt-3" >
        <div className="text-center">
        <img className="rounded-circle text-center" src={`https://www.csrkosovo.org/api/${board.image}`} alt="..." width="300"/>
        {lang === 'al' ? (
            <div>
            <p className="text-center bname mt-1">{board.name} {board.surname}</p>
            <p className="bprof mt-1">{board.position_on_csr}</p>
            <p className="bbottom mt-3"></p>
            <h5 className="bprof-1">{board.position_job}</h5>
            </div>
        ): (
            <div>
                <h2 className="text-center bname mt-1">{board.name} {board.surname}</h2>
                <p className="bprof mt-1">{board.position_on_csr_en}</p>
                <p className="bbottom mt-3"></p>
                <p className="bprof-1">{board.position_job_en}</p>
            </div>
        )}
     
               
        </div>
        </div>

    )

}

export default BoardImage